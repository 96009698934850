import {createContext, ReactNode, Ref, useEffect, useMemo, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import storage from "../../services/storage/localStorage";
import {ERole} from "../../constants/enums";
import {IAuthUser, ILoginRequest} from "../../models/IAuth";

import {decodeToken} from "../helpers/jwt";
import authApi from "../../services/api/authApi";
import {toast} from "react-toastify";

interface IAuthContext {
    user: IAuthUser | null;
    login: (email?: IEmailLogin, state?: LocationProps,) => Promise<void>
    logout: () => void

}


interface LocationProps {
    state: {
        from: Location;
    };
};


interface IEmailLogin {
    email: string;
    password: string
}

export const AuthContext = createContext({} as IAuthContext);

export const AuthProvider = ({children}: { children: ReactNode }) => {
        const [user, setUser] = useState<IAuthUser | null>(storage.get('user'));
        const navigate = useNavigate();

        const {state} = useLocation() as LocationProps;


        const handleLoginSuccess = (obj: IAuthUser, from?: string) => {
            // const obj = {
            //     id: res.id,
            //     email: res.email,
            //     accessToken: res.accessToken,
            //     imageUrl: res.imageUrl,
            //     name: res.name,
            //     role: res.role,
            // }
            setUser(obj)
            storage.set('user', obj)
            if ( obj.role == ERole.SuperAdmin) {
                navigate(from || "/dashboard/hotels");
            } else if (obj.role == ERole.HotelAdmin) {
                navigate(from || "/dashboard/partners");

            } else if (obj.role == ERole.Guest) {
                navigate(from || "/home");
            } else {
                toast.error("Permission denied.! \n Please login with your HR Admin account.")
            }

        }
        console.log(user);
        const handleLogOut = () => {
            setUser(null)
            storage.clear()


        }

// call this function when you want to authenticate the user
        const login = async (emailPass?: IEmailLogin, state?: LocationProps,) => {
            console.log(state);

            const requestBody: ILoginRequest = {
                email: emailPass?.email,
                password: emailPass?.password,


            }
            authApi.userLogin(requestBody)
                .then(res => {
                    console.log(res);
                    handleLoginSuccess(res)
                }).catch((e) => {

                toast.error(e.response.data.message)
                // handleLogOut()
                console.log("api error");
            })

            // navigate("/dashboard");
        };

// call this function to sign out logged user
        const logout = () => {

            handleLogOut()


            navigate("/", { replace: true });
        };

        const value = useMemo(
            () => ({
                user,
                login,
                logout,
            }),
            [user]
        );
        return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
    }
;
