import React from 'react';
import {Link} from "react-router-dom"
import homeImg from "../../assets/img/home.png";
// style={{backgroundImage: `url(${homeImg})`}}
const GuestFooter = () => {
    return (
        <div  className="pt-3 relative text-white bg-cover bg-black bg-bottom flex flex-col gap-2 justify-center items-center">
             <ul className="flex gap-5 justify-center items-center cursor-pointer">
                <li className="rounded bg-transparent border-b border-gold hover:border transition-all p-3">
                    TURN DOWN
                </li>
                 <Link to="/home" className="rounded bg-transparent border-b border-gold hover:border transition-all p-3">
                    HOME
                </Link>
                 <li className="rounded bg-transparent border-b border-gold hover:border transition-all p-3">
                    SIGN OUT
                </li>

            </ul>
            <ul className="flex gap-5 justify-center items-center">
                <li className="hover:border-gold border-transparent border rounded-full backdrop-blur-sm p-3 flex justify-center items-center">
                    <svg className="fill-white" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                         width="26" height="26"
                         viewBox="0 0 26 26">
                        <path
                            d="M 25.855469 5.574219 C 24.914063 5.992188 23.902344 6.273438 22.839844 6.402344 C 23.921875 5.75 24.757813 4.722656 25.148438 3.496094 C 24.132813 4.097656 23.007813 4.535156 21.8125 4.769531 C 20.855469 3.75 19.492188 3.113281 17.980469 3.113281 C 15.082031 3.113281 12.730469 5.464844 12.730469 8.363281 C 12.730469 8.773438 12.777344 9.175781 12.867188 9.558594 C 8.503906 9.339844 4.636719 7.246094 2.046875 4.070313 C 1.59375 4.847656 1.335938 5.75 1.335938 6.714844 C 1.335938 8.535156 2.261719 10.140625 3.671875 11.082031 C 2.808594 11.054688 2 10.820313 1.292969 10.425781 C 1.292969 10.449219 1.292969 10.46875 1.292969 10.492188 C 1.292969 13.035156 3.101563 15.15625 5.503906 15.640625 C 5.0625 15.761719 4.601563 15.824219 4.121094 15.824219 C 3.78125 15.824219 3.453125 15.792969 3.132813 15.730469 C 3.800781 17.8125 5.738281 19.335938 8.035156 19.375 C 6.242188 20.785156 3.976563 21.621094 1.515625 21.621094 C 1.089844 21.621094 0.675781 21.597656 0.265625 21.550781 C 2.585938 23.039063 5.347656 23.90625 8.3125 23.90625 C 17.96875 23.90625 23.25 15.90625 23.25 8.972656 C 23.25 8.742188 23.246094 8.515625 23.234375 8.289063 C 24.261719 7.554688 25.152344 6.628906 25.855469 5.574219"></path>
                    </svg>
                </li>
                <li className="hover:border-gold border border-transparent rounded-full backdrop-blur-sm p-3 flex justify-center items-center">
                 <svg className="fill-white" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                      width="26" height="26"
                      viewBox="0 0 24 24">
                     <path d="M 8 3 C 5.243 3 3 5.243 3 8 L 3 16 C 3 18.757 5.243 21 8 21 L 16 21 C 18.757 21 21 18.757 21 16 L 21 8 C 21 5.243 18.757 3 16 3 L 8 3 z M 8 5 L 16 5 C 17.654 5 19 6.346 19 8 L 19 16 C 19 17.654 17.654 19 16 19 L 8 19 C 6.346 19 5 17.654 5 16 L 5 8 C 5 6.346 6.346 5 8 5 z M 17 6 A 1 1 0 0 0 16 7 A 1 1 0 0 0 17 8 A 1 1 0 0 0 18 7 A 1 1 0 0 0 17 6 z M 12 7 C 9.243 7 7 9.243 7 12 C 7 14.757 9.243 17 12 17 C 14.757 17 17 14.757 17 12 C 17 9.243 14.757 7 12 7 z M 12 9 C 13.654 9 15 10.346 15 12 C 15 13.654 13.654 15 12 15 C 10.346 15 9 13.654 9 12 C 9 10.346 10.346 9 12 9 z"></path>
                 </svg>
                </li>
                <li className="hover:border-gold border border-transparent rounded-full backdrop-blur-sm p-3 flex justify-center items-center">
                 <svg className="fill-white" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                      width="26" height="26"
                      viewBox="0 0 24 24">
                     <path d="M12,2C6.477,2,2,6.477,2,12c0,5.013,3.693,9.153,8.505,9.876V14.65H8.031v-2.629h2.474v-1.749 c0-2.896,1.411-4.167,3.818-4.167c1.153,0,1.762,0.085,2.051,0.124v2.294h-1.642c-1.022,0-1.379,0.969-1.379,2.061v1.437h2.995 l-0.406,2.629h-2.588v7.247C18.235,21.236,22,17.062,22,12C22,6.477,17.523,2,12,2z"></path>
                 </svg>
                </li>
            </ul>
            <span className="absolute bottom-5 right-5 text-white">Powered by <b>Touch Tab</b></span>

        </div>
    );
};

export default GuestFooter;
