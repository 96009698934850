 import React, { useState } from "react";
 import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
 interface MyDatePickerProps extends ReactDatePickerProps {
     // add any additional props you need here
 }

const MyDatePicker = (props:MyDatePickerProps) => {
    return (
        <DatePicker className="z-20"  {...props} />
    );
};

export default MyDatePicker;
