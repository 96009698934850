import React from 'react';
import wifiIcon from "../../../assets/img/icons/wifi-outlined.png";
import nearbyIcon from "../../../assets/img/icons/nearby.png";
import restaurantIcon from "../../../assets/img/icons/restaurant.png";
import barIcon from "../../../assets/img/icons/bar.png";
import businessIcon from "../../../assets/img/icons/business-center.png";
import newspaperIcon from "../../../assets/img/icons/newsPaper.png";
import babySitterIcon from "../../../assets/img/icons/babysitter.png";
import rewardsIcon from "../../../assets/img/icons/gift.png";
import {Link} from "react-router-dom";
import Activities from "../pages/Activities";

interface Props {
    text: string
    icon: any
    bottom?: boolean
    path?:string
}

const HomeTile = (props: Props) => {
    return <Link to={props.path || '#'}
        className={`transition-all uppercase font-bold items-center justify-center flex flex-col p-5 hover:bg-gold bg-transparent border-white ${!props.bottom && 'border-b'} border-r border-1 last:border-r-transparent `}>
        <img className="h-16" src={props.icon}/>
        {props.text}
    </Link>
}
const HomeNavigator = () => {
    return (
        <div className="backdrop-blur-sm p-5 text-white rounded-md overflow-hidden shadow-md">
            <div className="rounded-md overflow-hidden">
                <div className="grid grid-flow-col auto-cols-fr">

                    <HomeTile path="/wifi" text="wifi" icon={wifiIcon}/>
                    <HomeTile path="/activities" text="Activities" icon={nearbyIcon}/>
                    <HomeTile path="/restaurants" text="Buffet & Restaurants" icon={restaurantIcon}/>
                    <HomeTile text="SALON & BAR" icon={barIcon}/>

                </div>
                <div className="grid grid-flow-col auto-cols-fr">

                    <HomeTile path="/meeting-rooms"  text="Meeting Rooms" icon={businessIcon} bottom={true}/>
                    <HomeTile text="DAILY NEWSPAPER" icon={newspaperIcon} bottom={true}/>
                    <HomeTile path="/baby-sitters" text="Child Care " icon={babySitterIcon} bottom={true}/>
                    <HomeTile text="REWARDS" icon={rewardsIcon} bottom={true}/>


                </div>


            </div>
        </div>
    );
};

export default HomeNavigator;
