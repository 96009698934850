import React, {useEffect, useState} from 'react';
import {GET} from "../../../utils/lib/axios";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";
import MeetingRoomCard from "../components/MeetingRoomCard";
import BoatCard from "../components/BoatCard";
import useAuth from "../../../utils/hooks/useAuth";

const Boat = () => {
    const [boats, setBoats] = useState<Record<string, any>[]>([]);
    const {user} = useAuth()

    const loadBoats = () => {
        GET({path: "/hotels/{id}/boats"}, {id: user?.hotel?.id}).then(r => {
            setBoats(r.data)
        }).catch(e => toast.error(e.response.data.message));
    }
    useEffect(() => {
        loadBoats()
    }, [])
    return (

        <div className="p-5 grid grid-cols-3 grid-rows-2 h-full gap-5 ">
            {boats.map((boat, i) =>
                <Link to={`./${boat.id}`}
                      className={``}>
                    <BoatCard  item={boat}
                    />

                </Link>
            )}


        </div>

    );
};

export default Boat;
