import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import {ERole} from "../../constants/enums";
import {toast} from "react-toastify";
import useAuth from "../../utils/hooks/useAuth";

const Default = () => {
    const navigate = useNavigate();
    const {user} = useAuth()
    console.log('default route')
    useEffect(() => {
        // if (user?.role == ERole.HrAdmin || user?.role == ERole.SuperAdmin) {
        //     navigate("/dashboard/coaching");
        // } else if (user?.role == ERole.ItAdmin) {
        //     navigate("/dashboard/integration");
        //
        // } else if (user?.role == ERole.Coach) {
        //     navigate("/dashboard/reports");
        // } else {
        //     toast.error("Permission denied.! \n Please login with your HR Admin account.")
        // }
    }, [user])

    return (
        <>

        </>
    );
};

export default Default;
