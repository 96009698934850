import React, {useEffect, useState} from 'react';
import AccountSetupMultiTemplateWithDropDown from "../../../shared/components/AccountSetupMultiTemplateWithDropDown";
import useAuth from "../../../utils/hooks/useAuth";
import {GET, POST} from "../../../utils/lib/axios";
import {toast} from "react-toastify";

const HotelAdmins = ({hotelId, hotelName}: { hotelId?: number, hotelName?: string }) => {
    const [data, setData] = useState({hotelAdmins: []})
    const {user} = useAuth()
    console.log(hotelName);
    const [options, setOptions] = useState<Record<string, any>[]>([]);

    const loadOptions = () => {
        GET({path: "/hotels"})
            .then(r => {
                setOptions(r.data.map(((item: any, i: number) => {
                        console.log((item));
                        return {label: item.name, value: item.id}
                    }))
                )

            }).catch(e => toast.error(e.response.data.message));
    }
    useEffect(() => {
        loadOptions()
    }, [])

    const handleSave = (data: Record<string, any>) => {
        const body = {
            name: data.contactPerson,
            email: data.email,
            phone: data.phone
        }
        POST({path: "/hotels/{id}/admin"}, body, {id: data.dropdownId})
            .then(r => {
                toast.success(r.data.message)
loadData()
            }).catch(e => toast.error(e.response.data.message));
    }
    const handleDelete = (data: Record<string, any>) => {


    }
    const loadData = () => {
        const api = hotelId ? GET({path: "/hotels/{id}/admins"}, {id: hotelId}) : GET({path: "/users/hotelAdmins"})

        api.then(r => {
            setData({
                hotelAdmins: r.data.map(((item: any, i: number) => {
                    return {
                        no: i + 1,
                        id: item.id,
                        dropdownId: item.hotel.id,
                        dropdownLabel: item.hotel.name,
                        email: item.email,
                        contactPerson: item.name,
                        phone:item.phone
                    }
                }))
            })

        }).catch(e => toast.error(e.response.data.message));
    }
    useEffect(() => {
        loadData()
    }, [])
    return (
        <div className="p-5">
            <AccountSetupMultiTemplateWithDropDown
                options={options}
                defaultOption={options.find(({value}) => value === hotelId)}
                keyArr={[{key: 'contactPerson', header: 'Contact Person'}, {
                    key: 'email',
                    header: 'Email'
                }, {key: 'phone', header: 'Phone Number'},]}
                key="s1" data={data?.hotelAdmins} name={"hotelAdmins"}
                handleSave={handleSave}
                // handleDelete={handleDelete}
                setData={setData}
                title=""
                des=""
                addingTitle=""
                dropdownHeader="Hotel"/>
        </div>
    );
};

export default HotelAdmins;
