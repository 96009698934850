import React, {Component, ComponentType, ReactNode} from 'react';
import {Navigate, Route, useLocation, RouteProps} from "react-router-dom";
import {IRoute, ISubRoute} from "../../models/IRoute";
 import IAuthRouterProp from "../../models/IAuthRouter";
import useAuth from "../../utils/hooks/useAuth";


const PrivateRoute = ({Component, allowedRoles, props}: IAuthRouterProp) => {
    const {user} = useAuth();
    const location = useLocation();
    console.log(user);

    if (!user) {
        return <Navigate to="/auth/login" replace state={{from: location.pathname}}/>
    }
    // check if route is restricted by role
    if (allowedRoles && (allowedRoles?.indexOf(user.role) === -1)) {
        // role not authorised so redirect to home page
        return <Navigate to="/404" replace/>
    }
    return <Component {...props}/>
};

export default PrivateRoute;
