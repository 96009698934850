import React, {useRef, useState} from 'react';

import Table from "./Table";
import Input from "./form/Input";
import Button from "./form/Button";

interface Props {
    title: string;
    name: string;
    // name: keyof IAccountSetup;
    des: string;
    addingTitle: string;
    keyArr: Array<Record<string, any>>
    data: Array<Record<string, any>>;
    handleSave?: (data: Record<string, any>) => void
    handleDelete?: (id: number) => void
    setData: React.Dispatch<React.SetStateAction<any>>;
    onRowClick?: (data: any) => void;

}

const AccountSetupMultiTemplate = ({data, setData, name, keyArr, ...props}: Props) => {
    const resetValue = () => keyArr.reduce((dict, {key}) => ({...dict, [key]: ''}), {})
    const [value, setValue] = useState<Record<string, any>>(resetValue())
    console.log(data);

    const addValue = () => {
        const obj = keyArr.reduce((dict, {key}) => ({...dict, [key]: value ? value[key] : ''}), {});
        if (props.handleSave) {
            props.handleSave(value)
        }
        setData((prevState: any) => ({
            ...prevState,
            [name]: [...(prevState[name]), {
                no: prevState[name].length + 1,
                ...obj,
            }]
        }))
        setValue(resetValue())
    }
    // const addValue = () => {
    //
    //     setData((prevState) => [...prevState, {
    //         id: prevState.length + 1,
    //         value: value
    //     }])
    //     setValue('')
    // }
    const columns = React.useMemo(
        () => [
            {
                Header: "Id",
                accessor: "id",

            }, {
                Header: "No",
                accessor: "no",
                // Cell: (cell: any) => <NameCell icon={cell.row.original.image_url} text={cell.value}/>

            },
            ...keyArr.map(({key, header}, index) => ({
                Header: header,
                accessor: key,
                Cell: (cell: any) => (index === keyArr.length - 1) && props.handleDelete ? <div  className="flex gap-3 justify-between"> {cell.value}
                    <button className="text-myOrange hover:text-red-700" onClick={() => {
                        if (props.handleDelete && cell.row.original.id) {
                            props.handleDelete(cell.row.original.id)
                        }
                        // ES6 Syntax use the rvalue if your data is an array.
                        const dataCopy = [...data];
                        // It should not matter what you name tableProps. It made the most sense to me.
                        dataCopy.splice(cell.row.index, 1);
                        setData((prevState: Record<string, any>) => ({
                            ...prevState,
                            [name]: dataCopy
                        }))
                    }}>&#x2715;</button>
                </div> : cell.value

            }))

        ],
        [data]
    );
    console.log(columns);

    return (
        <div>
            <h4 className="pb-4">{props.title}</h4>
            <p>{props.des}</p>

            <div className="mt-8">
                <p>{props.addingTitle}</p>
                <div className="mb-5 flex gap-0.5 items-end">
                    {keyArr.map((({key}, i) =>
                        <Input key={i} label={key.charAt(0).toUpperCase() + key.slice(1)} value={value?.[key]}
                               onChange={(e) => setValue(prevState => ({
                                   ...prevState,
                                   [key]: e.target.value
                               }))}
                               className="mr-4" name={key}/>))}

                    {/*<Input label="Email" value={value?.email} onChange={(e) => setValue(prevState => ({*/}
                    {/*    ...prevState,*/}
                    {/*    email: e.target.value*/}
                    {/*}))}*/}
                    {/*       className="mr-4" name={"email"}/>*/}
                    {/*<Input label="Team" value={value?.team} onChange={(e) => setValue(prevState => ({*/}
                    {/*    ...prevState,*/}
                    {/*    team: e.target.value*/}
                    {/*}))}*/}
                    {/*       className="mr-4" name={"team"}/>*/}
                    <Button onClick={() => addValue()}>Add</Button>
                </div>
                <div className="w-full">
                    {data?.length! > 0 && <Table onRowClick={props.onRowClick} initialState={{hiddenColumns: ['id']}} columns={columns} data={data!}/>}

                </div>

            </div>
        </div>
    );
};

export default AccountSetupMultiTemplate;
