import ISidebarMenu from "../../models/ISidebarMenu";
import {AcademicCapIcon, ChartPieIcon, ChartSquareBarIcon, ChatAltIcon, KeyIcon} from "@heroicons/react/outline";
import {ERole} from "../enums";

export const SidebarData: ISidebarMenu[] = [
    {
        title: 'Hotels',
        icon: <ChatAltIcon/>,
        path: "hotels",
        allowedRoles: [ ERole.SuperAdmin]

        // subMenu: [
        //     {
        //         icon: <FaIcons.FaDeezer/>,
        //         title: 'Tenants',
        //         path: "Tenants"
        //     }, {
        //         icon: <FaIcons.FaGripfire/>,
        //         title: 'Landlords',
        //         path: "Landlords",
        //     }, {
        //         icon: <FaIcons.FaMagento/>,
        //         title: 'Ponols',
        //         path: "Ponols",
        //     },
        // ]
    },
    {
        icon: <ChartPieIcon/>,
        path: "hotel-admins",
        title: 'Hotel Admins',
        allowedRoles: [ERole.SuperAdmin],
        disable: false

    },
    // {
    //     icon: <ChartSquareBarIcon/>,
    //     path: "services",
    //     title: 'Services',
    //     allowedRoles: [ERole.SuperAdmin,ERole.HotelAdmin],
    //     disable: false
    // },
    {
        icon: <AcademicCapIcon/>,
        path: "partners",
        title: 'Partners',
        allowedRoles: [ERole.SuperAdmin,ERole.HotelAdmin],
        disable: false
    },

    {
        icon: <KeyIcon/>,
        path: "partner-admins",
        title: 'Partner Admins',
        allowedRoles: [ERole.SuperAdmin,ERole.HotelAdmin],
        disable: false

    },
 {
        icon: <KeyIcon/>,
        path: "meeting-rooms",
        title: 'Meeting Rooms',
        allowedRoles: [ERole.PartnerAdmin,ERole.HotelAdmin],
        disable: false

    },

 {
        icon: <KeyIcon/>,
        path: "restaurants",
        title: 'Restaurants',
        allowedRoles: [ERole.PartnerAdmin,ERole.HotelAdmin],
        disable: false

    },

 {
        icon: <KeyIcon/>,
        path: "food-menu",
        title: 'Food Menu',
        allowedRoles: [ERole.HotelAdmin,ERole.PartnerAdmin],
        disable: false

    },
 {
        icon: <KeyIcon/>,
        path: "check-in-out",
        title: 'Check In',
        allowedRoles: [ERole.HotelAdmin],
        disable: false

    },

]
