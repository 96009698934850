import React, {ButtonHTMLAttributes} from 'react';

const Button = ({children,className,...props}: ButtonHTMLAttributes<HTMLButtonElement>) => {
    return (
        <button className={`dashboard-button bg-blue ${className}`} {...props}>
            {children}
        </button>
    );
};

export default Button;
