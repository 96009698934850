import React, {FC, HTMLAttributes, InputHTMLAttributes} from 'react';

import {Formik, Form, useField, FieldHookConfig, FieldInputProps, useFormikContext} from 'formik';


import style from "./SubmitFormik.module.css";

import Spinner from "../../Spinner/Spinner";

interface Props extends HTMLAttributes<HTMLButtonElement> {
    text: string;
    onSubmittingText: string;


}

const SubmitFormik: FC<Props> = ({text, onSubmittingText, ...props}) => {

    const {isSubmitting} = useFormikContext();
    return (

        <>
            <button
                    className={style.submitButton}
                    {...props}
                    disabled={isSubmitting}
                    type="submit"
            >
                {isSubmitting ? <>{onSubmittingText}
                        <Spinner size={"24px"}/></>
                    : text}
            </button>
        </>

    );

};

export default SubmitFormik;
