import React from 'react';
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";

import './assets/styles/fonts.css';
import './assets/styles/colour.css';
import './assets/styles/App.css';

import routes from "./routes";
import RouterMiddleware from "./routes/RouterMiddleware/RouterMiddleware";
import NotFound from "./shared/pages/NotFound";
import {AuthProvider} from "./utils/providers/AuthProvider";
import axios from "axios";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL


function App() {

    return (
        <BrowserRouter>
            <AuthProvider>
                <ToastContainer
                    position="top-center"
                    autoClose={9000}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <RouterMiddleware routes={routes}/>
                {/*<Routes> <Route path="/coaching" element={<Coaching/>}/>*/}
                {/*    <Route path="/usage" element={<Usage/>}/>*/}
                {/*    <Route path="/404" element={<NotFound/>}/>*/}
                {/*    <Route path="*" element={  <Navigate replace to="/404"/>}/>*/}
                {/*</Routes>*/}
            </AuthProvider>
        </BrowserRouter>


    );
}

export default App;
