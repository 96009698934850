import {CalendarIcon, SearchIcon} from "@heroicons/react/outline";
import React, {ButtonHTMLAttributes, InputHTMLAttributes} from 'react';

const CalenderButton = ({...props}:InputHTMLAttributes<HTMLInputElement>) => {
    return (
        <div className="py-2 relative mx-auto text-gray-600">
            <input {...props}
                   className="w-full border-2 border-gray-light bg-white h-10 px-5 pr-16 rounded text-sm focus:outline-none"

            />
            <button className="absolute right-0 top-0 mt-5 mr-4">
                <CalendarIcon className="text-gray-600 h-4 w-4"/>

            </button>
        </div>
    );
};

export default CalenderButton;
