import React, {ComponentType} from 'react';
import {ERole, ERouteType} from "../../constants/enums";
import {IRoute, ISubRoute} from "../../models/IRoute";
import {Navigate, Route, Routes} from "react-router-dom";

import NotFound from "../../shared/pages/NotFound";
import PrivateRoute from "../AuthRouter/PrivateRoute";
import PublicRoute from "../AuthRouter/PublicRoute";
import RestrictedRoute from "../AuthRouter/RestrictedRoute";
import CommonRoutes from "../common";
import {useNavTitle} from "../../utils/hooks/useNavTitle";

// if nested routes use in the inner components then it will be difficult to handle 404 error since not work even only
// use path="*" route in the top container. So we have to use notfound route in each branches in routes tree.
// So it is better to use custom Routes component that add notfound route in each Routes wrapper.
//  then we can use CustomRoutes component except using Routes component in the inner components.
// https://simbathesailor.dev/404-pages-for-nested-routes-react-router/

{/* use outlet handler to nested routes */
}
{/*index route for /dashboard. missing the company name in the url*/
}

interface AuthProps {
    routeType: ERouteType;
    allowedRoles?: ERole[];
    component: ComponentType<any>;
    props?: any
}

const AuthRoute = ({routeType, allowedRoles, component, props}: AuthProps) => {
    useNavTitle(props?.navTitle || 'TouchTab')

    if (routeType === ERouteType.private) {
        return <PrivateRoute Component={component} props={props} allowedRoles={allowedRoles}/>

    } else if (routeType === ERouteType.restricted) {
        return <RestrictedRoute Component={component} props={props}/>
    } else {
        return <PublicRoute Component={component} props={props}/>
    }
}

const constructSubRoutes = (routes: ISubRoute[]) => {

    return routes.map((route: ISubRoute, index) => {

        // @ts-ignore
        return <Route key={index} path={route.path ? route.path : undefined}
                      index={route.index}
                      element={<AuthRoute allowedRoles={route.allowedRoles} routeType={route.routeType} component={route.component}
                                          props={route.props}/>}>
            {route.routes && constructSubRoutes(route.routes)}
        </Route>
    })
};

interface Props {
    routes: IRoute[]
}

const RouterMiddleware = ({routes}: Props) => {

    const routers = routes.map((route: IRoute, key) => {
        return <Route key={key} path={route.path} element={<route.layout/>}>
            {route.component ?
                <Route key={key} index
                       element={<AuthRoute routeType={route.routeType} component={route.component}/>}/>
                : constructSubRoutes(route.routes)}

        </Route>
    });

    return <Routes>
        {routers}
        {/*when others don't match*/}
        <Route path="*" element={<Navigate replace to="/404"/>}/>
    </Routes>

};

export default RouterMiddleware;
