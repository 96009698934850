import React, {useEffect, useState} from 'react';
import RestaurantCard from "../components/RestaurantCard";
import homeImg from "../../../assets/img/restaurant.png";
import {GET} from "../../../utils/lib/axios";
import {IMeta} from "../../../models/IMeta";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";
import useAuth from "../../../utils/hooks/useAuth";
import hotels from "../../dashboard/pages/Hotels";

const Restaurant = () => {
    const [restaurants, setRestaurants] = useState<Record<string, any>[]>([]);
    const {user} = useAuth()

    const loadRestaurants = () => {
        GET({path: "/hotels/{id}/restaurants"}, {id: user?.hotel?.id}).then(r => {
            setRestaurants(r.data)
        }).catch(e => toast.error(e.response.data.message));
    }
    useEffect(() => {
        loadRestaurants()
    }, [])
    return (

        <div className="p-5 grid grid-cols-3 grid-rows-2 h-full gap-5 ">
            {restaurants.map((restaurant, i) =>
                <Link to={`./${restaurant.id}`}
                      className={``}>
                    <RestaurantCard name={restaurant.name}
                                    type={restaurant.cuisineType}
                                    imageUrl={restaurant.imageUrl || homeImg}
                                    openTime={restaurant.openTime}
                                    closeTime={restaurant.closeTime}
                                    description={restaurant.description}
                                    capacity={restaurant.capacity}
                    />

                </Link>
            )}


        </div>

    );
};

export default Restaurant;
