import {IRoute} from "../../../models/IRoute";
import {ComponentType} from "react";
import {ERouteType} from "../../../constants/enums";
import PageLayout from "../../../layouts/PageLayout";
import Login from "../pages/Login";

const AuthRoutes: IRoute[] = [
    {
        path: "/auth",
        layout: PageLayout,
        routeType: ERouteType.restricted,
        routes: [
            {
                name: 'Login',
                path: "login",
                component: Login,
                routeType: ERouteType.public,
            }
        ]

    },

];

export default AuthRoutes;
