// axios base functions
import axios, {Method} from "axios";
import IEndpoint from "../../models/IEndpoint";
 import {appendPathParams} from "../helpers/apiUtils";
 import storage from "../../services/storage/localStorage";
import {useEffect} from "react";
import queryString from 'query-string';
axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL + "/api/"

// without authorization header
const axiosPublic = axios.create({
    headers: {
        "Content-Type": "application/json",
    },
});
// with authorization header
const axiosPrivate = axios.create({
    headers: {
        "Content-Type": "application/json",
    },
});


axiosPrivate.interceptors.request.use(
    async (config) => {
        const user = storage.get('user');

        if (user?.accessToken) {
            // @ts-ignore
            config.headers = {
                ...config.headers,
                authorization: `Bearer ${user?.accessToken}`,
            };
        }
        // console.log(config)
        return config;
    },
    (error) => Promise.reject(error)
);

axiosPrivate.interceptors.response.use(
    (response) => response,
    async (error) => {
        const config = error?.config;
        if (error?.response?.status === 401 && !config?.sent) {
            config.sent = true;

            storage.clear()
            // const result = await memoizedRefreshToken();

            // if (result?.accessToken) {
            //     config.headers = {
            //         ...config.headers,
            //         authorization: `Bearer ${result?.accessToken}`,
            //     };
            // }

            // return axios(config);
        }
        return Promise.reject(error);
    }
);

const apiCall = (
    method: Method,
    endpoint: IEndpoint,
    pathParams: Record<string, unknown> | undefined = undefined,
    queryParams: Record<string, unknown> | undefined = undefined,
    data: object | undefined = undefined,
) => {
    const url = createUrl(appendPathParams(endpoint.path, pathParams), queryParams);
    const options = createOptions(url, method, data);
    let axiosInstance = axiosPublic

    // console.log(endpoint.private);
    if (endpoint.private) {
        axiosInstance = axiosPrivate
    }
    return axiosInstance
        .request(options)
        .then((response) => {
            return (response)
        })
};


const createUrl = (
    endPoint: string,
    queryParams?: Record<any, any>
): string => {
    let params = '';
    if (queryParams) {
        params += `?${ queryString.stringify(queryParams,
            {
                skipNull: true
            }
          )}`;
    }

    return endPoint + params;
};

const createOptions = (
    url: string,
    method: Method,
    data: object | undefined,
) => ({
    url,
    method,
    data,

});

const GET = (
    endpoint: IEndpoint,
    pathParams?: Record<any, any>,
    queryParams?: Record<any, any>,
) => {

    return apiCall('GET', endpoint, pathParams, queryParams).then(r => r)

}


const POST = (
    endpoint: IEndpoint,
    data: object,
    pathParams?: Record<any, any>,
    queryParams: Record<string, unknown> | undefined = undefined,
) =>
    apiCall('POST', endpoint, pathParams, queryParams, data);

const PUT = (
    endPoint: IEndpoint,
    data: object,
    pathParams?: Record<any, any>,
    queryParams: Record<string, unknown> | undefined = undefined,
) =>
    apiCall('PUT', endPoint, pathParams, queryParams, data);

const DELETE = (
    endPoint: IEndpoint,
    data?: object,
    pathParams?: Record<any, any>,
    queryParams: Record<string, unknown> | undefined = undefined,
) =>
    apiCall('DELETE', endPoint, pathParams, queryParams, data);

const PATCH = (
    endPoint: IEndpoint,
    data: object,
    pathParams?: Record<any, any>,
    queryParams: Record<string, unknown> | undefined = undefined,
) =>
    apiCall('PATCH', endPoint, pathParams, queryParams, data);

export {axiosPrivate, axiosPublic, GET, PUT, POST, DELETE, PATCH};
