export enum ERouteType {
    private = "private",
    public = "public",
    restricted = "restricted",

}


export enum ERole {
    SuperAdmin = 'super_admin',
    HotelAdmin = 'hotel_admin',
    PartnerAdmin = 'partner_admin',
    Guest = 'guest',
}

export enum EFoodType {
    Starters = 'starters',
    MainCourse = 'main_course',
    Desserts = 'desserts',
    Drinks = 'drinks'
}
