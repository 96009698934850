 import moment from "moment";
 import React, {Dispatch, forwardRef, SetStateAction, useEffect, useState} from 'react'
import {ChevronLeftIcon, ChevronRightIcon, FilterIcon} from '@heroicons/react/solid'
import {DateRange} from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css';
import DropdownWrapper from "./DropdownWrapper";
import CalenderButton from "./Table/CalenderButton";
import {CalendarSelectionType} from "../../models";

export default function MyDataRange({
                                        state,
                                        setState,
                                        // onApply
                                    }: {
    state: CalendarSelectionType[];
    setState: Dispatch<SetStateAction<CalendarSelectionType[]>>;
    // onApply: (startDate: any, endDate: any) => void
}) {

    return (<>
        <div className="">
            <DropdownWrapper button={
                <div className="flex gap-3 sm:flex-row flex-col">
                    <CalenderButton placeholder="Date" type="text"
                                    value={moment(state[0].startDate?.toString()).format("YYYY-MM-DD") + " : " + moment(state[0].endDate?.toString()).format("YYYY-MM-DD")}
                                    readOnly/>

                </div>}
                             body={<div>
                                 <DateRange editableDateInputs={true}
                                            onChange={(item: any) => setState([item.selection])}
                                            moveRangeOnFirstSelection={false}
                                            rangeColors={['rgba(253,0,84)']}

                                     // @ts-ignore
                                            ranges={state}/>
                                 {/*<div className="p-2">*/}
                                 {/*    <button onClick={() => onApply(state[0].startDate!, state[0].endDate!)}*/}
                                 {/*            className="bg w-full bg-black text-white rounded-md p-2 hover:shadow-lg">*/}
                                 {/*        Apply*/}
                                 {/*    </button>*/}
                                 {/*</div>*/}
                             </div>}
                             bodyWrapperClassName=" "/>
        </div>

    </>)
}
