import React, {ComponentType} from 'react';
import {Navigate, Route} from "react-router-dom";
import IAuthRouterProp from "../../models/IAuthRouter";
import useAuth from "../../utils/hooks/useAuth";


const RestrictedRoute  = ({Component,props}:IAuthRouterProp  ) => {
  const {user} = useAuth();

  return !user ? <Component {...props}/> : <Navigate to="/dashboard" />
};

export default RestrictedRoute;
