
import {ReactComponent as notificationIcon} from "@icons/notification.svg";
import {useTranslation} from "react-i18next";
 import Select from "react-select";
import {LanguageSelectorData} from "../../constants/data/LanguageSelectorData";
import React, {useEffect, useState} from "react";
 import metaApi from "../../services/api/metaApi";
import storage from "../../services/storage/localStorage";
import {ERole} from "../../constants/enums";
import {SidebarData} from "../../constants/data/SidebarData";
import UserDropdownItemWrapper from "./UserDropdownItemWrapper";
import {Link} from "react-router-dom";
import useAuth from "../../utils/hooks/useAuth";
import {DropdownWrapper} from "../../shared/components";


const Navbar = ({navTitle}: { navTitle?: string }) => {
    const {t} = useTranslation('layout')
    const {logout, user} = useAuth()


     const [companies, setCompanies] = useState<any[]>([]);

    // handle onChange event of the dropdown
    const handleChange = (e: any) => {

        let obj = storage.get('user')
        obj.companyId = e.value
        obj.companyName = e.label
        storage.set('user', obj)
        window.location.reload()
    }
    useEffect(() => {
            // metaApi.getCompanies()
            //     .then(res => {
            //         setCompanies(res.map((obj: any) => {
            //             return {
            //                 label: obj.name,
            //                 value: obj.id
            //             }
            //         }))
            //
            //     }).catch((e) => {
            //     console.log("api error");
            // })
        }, []
    )

    return (
        <nav className="flex items-center justify-between px-6 py-3 bg-white">
            <div className="text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap">
                <h4>{navTitle || <span className="">&#127976;</span>}</h4>
            </div>
            <div className="flex items-center gap-3">

                <DropdownWrapper
                    button={
                        <div
                            className="cursor-pointer flex items-center justify-between gap-1.5 group">
                            <img
                                className="w-10 h-10 rounded-full object-cover group-hover:ring-4 group-hover:ring-red"
                                src={user?.imageUrl || "https://avatars.dicebear.com/api/micah/1.jpg"}/>
                            <span
                                className=""> {t('navbar.text1')},</span>
                            <span
                                className="hidden sm:block"> {user?.name} !</span>
                            <svg className="ml-1 w-4 h-4" aria-hidden="true" fill="currentColor"
                                 viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd"
                                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                      clip-rule="evenodd"></path>
                            </svg>
                        </div>
                    }
                    body={<>
                        <div className="py-1">
                            {/*<UserDropdownItemWrapper userRole={user?.role}>*/}
                            {/*    <Link to="profile"*/}
                            {/*          className="text-gray-700 block px-4 py-2 text-sm w-full">{t('navbar.text3')}*/}
                            {/*    </Link>*/}
                            {/*</UserDropdownItemWrapper>*/}
                            {/*todo:Include with the Calendar update*/}
                            {/*<UserDropdownItemWrapper userRole={user?.role}>*/}
                            {/*    <Link to="settings"*/}
                            {/*          className="text-gray-700 block px-4 py-2 text-sm w-full">{t('navbar.text4')}*/}
                            {/*    </Link>*/}
                            {/*</UserDropdownItemWrapper>*/}
                            <hr/>
                            <UserDropdownItemWrapper userRole={user?.role}>
                                <button onClick={() => logout()}
                                        className="text-gray-700 block px-4 py-2 text-sm w-full">{t('navbar.text2')}
                                </button>
                            </UserDropdownItemWrapper>


                        </div>

                    </>
                    }/>
            </div>

        </nav>
    );
};

export default Navbar;
//
// const Navbar = () => {
//     const [navbarOpen, setNavbarOpen] = React.useState(false);
//
//     return (<>
//         <nav className="relative flex flex-wrap items-center justify-between px-2 py-3 bg-white mb-3">
//             <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
//                 <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
//                     <Link
//                         className="text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap"
//                         to="#">
//                         <h4>Coaching</h4>
//
//                     </Link>
//                     <button
//                         className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
//                         type="button"
//                         onClick={() => setNavbarOpen(!navbarOpen)}>
//                         <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20"
//                              xmlns="http://www.w3.org/2000/svg">
//                             <path fill-rule="evenodd"
//                                   d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
//                                   clip-rule="evenodd"></path>
//                         </svg>
//
//                     </button>
//                 </div>
//                 <div
//                     className={
//                         "lg:flex flex-grow items-center" +
//                         (navbarOpen ? " flex" : " hidden")
//                     }
//
//                 >
//                     <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
//                         <li className="nav-item">
//                             <div
//                                 className="px-3 py-2 flex items-center text-xs font-bold leading-snug hover:opacity-75"
//                             >
//                                 <i className="fab fa-facebook-square text-lg leading-lg opacity-75"></i><span
//                                 className="ml-2">Share</span>
//                             </div>
//                         </li>
//                         <li className="nav-item">
//
//
//
//
//                         </li>
//
//                     </ul>
//                 </div>
//                 <DropdownWrapper button={
//                     <div
//                         className="px-3 py-2 flex items-center text-xs font-bold leading-snug">
//                         <img className="w-10 h-10 rounded-full"
//                              src="https://images.unsplash.com/photo-1610397095767-84a5b4736cbd?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80"/>
//                         <span
//                             className="ml-2">Lasitha</span>
//                         <span
//                             className="ml-2">Jayawardana</span>
//                         <svg className="ml-1 w-4 h-4" aria-hidden="true" fill="currentColor"
//                              viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
//                             <path fill-rule="evenodd"
//                                   d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
//                                   clip-rule="evenodd"></path>
//                         </svg>
//                     </div>
//                 } body={<>
//                     <div className="py-1" role="none">
//
//                         <a href="#" className="text-gray-700 block px-4 py-2 text-sm" role="menuitem"
//                         >Edit</a>
//                         <a href="#" className="text-gray-700 block px-4 py-2 text-sm" role="menuitem"
//                         >Duplicate</a>
//                     </div>
//                     <div className="py-1" role="none">
//                         <a href="#" className="text-gray-700 block px-4 py-2 text-sm" role="menuitem"
//                         >Archive</a>
//                         <a href="#" className="text-gray-700 block px-4 py-2 text-sm" role="menuitem"
//                         >Move</a>
//                     </div>
//                     <div className="py-1" role="none">
//                         <a href="#" className="text-gray-700 block px-4 py-2 text-sm" role="menuitem"
//                         >Share</a>
//                         <a href="#" className="text-gray-700 block px-4 py-2 text-sm" role="menuitem"
//                         >Add to favorites</a>
//                     </div>
//                     <div className="py-1" role="none">
//                         <a href="#" className="text-gray-700 block px-4 py-2 text-sm" role="menuitem"
//                         >Delete</a>
//                     </div>
//                 </>
//                 }/>
//             </div>
//         </nav>
//
//         <nav className="bg-white border-gray-200 px-2 sm:px-4 py-2.5 rounded dark:bg-gray-900">
//             <div className="container flex flex-wrap justify-between items-center mx-auto">
//                 <a href="https://flowbite.com/" className="flex items-center">
//                     <img src="https://flowbite.com/docs/images/logo.svg" className="mr-3 h-6 sm:h-9"
//                          alt="Flowbite Logo"/>
//                         <span
//                             className="self-center text-xl font-semibold whitespace-nowrap dark:text-white">Flowbite</span>
//                 </a>
//                 <div className="flex md:order-2">
//                     <button type="button"
//                             className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 md:mr-0 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Get
//                         started
//                     </button>
//                     <button  onClick={() => setNavbarOpen(!navbarOpen)}  type="button"
//                             className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
//                             >
//                         <span className="sr-only">Open main menu</span>
//                         <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20"
//                              xmlns="http://www.w3.org/2000/svg">
//                             <path fill-rule="evenodd"
//                                   d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
//                                   clip-rule="evenodd"></path>
//                         </svg>
//                     </button>
//                 </div>
//                 <div className={"justify-between items-center w-full md:flex md:w-auto md:order-1" +
//                     (navbarOpen ? " flex" : " hidden")}
//                      id="navbar-cta">
//                     <ul className="flex flex-col p-4 mt-4 bg-gray-50 rounded-lg border border-gray-100 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
//                         <li>
//                             <a href="#"
//                                className="block py-2 pr-4 pl-3 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 dark:text-white"
//                                aria-current="page">Home</a>
//                         </li>
//                         <li>
//                             <a href="#"
//                                className="block py-2 pr-4 pl-3 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-white dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">About</a>
//                         </li>
//
//                     </ul>
//                 </div>
//             </div>
//         </nav>
//
//     </>
//     );
// };
//
// export default Navbar;
