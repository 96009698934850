//
// import React, { useState } from "react";
//
// interface Props {
//     pages: number;
//     page: number;
//     pageSize: number;
//     onPageChange: Function;
//     previousText: string;
//     nextText: string;
//     dataLength: number;
// }
//
// const PaginationButton = (props: any) => (
//     <button {...props}>{props.children}</button>
// )
//
// const Pagination = ({
//                         dataLength,
//                         page,
//                         pages,
//                         pageSize,
//                         onPageChange,
//                         previousText,
//                         nextText
//                     }:Props) => {
//
//     const filterPages = (visiblePages: number[], totalPages: number) => {
//         return visiblePages.filter(page => page <= totalPages)
//     }
//     const [visiblePages, setVisiblePage] = useState<number[]>([]);
//
//
//     const getVisiblePages = (page: number, total: number) => {
//         if (total < 7) {
//             return filterPages([1, 2, 3, 4, 5, 6], total)
//         } else {
//             if (page % 5 >= 0 && page > 4 && page + 2 < total) {
//                 return [1, page - 1, page, page + 1, total]
//             } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
//                 return [1, total - 3, total - 2, total - 1, total]
//             } else {
//                 return [1, 2, 3, 4, 5, total]
//             }
//         }
//     }
//
//     const changePage = (nextPage: number) => {
//         const activePage = page + 1
//
//         if (nextPage === activePage) {
//             return
//         }
//
//         const visiblePages = getVisiblePages(nextPage, pages)
//         setVisiblePage(filterPages(visiblePages, pages))
//         onPageChange(nextPage - 1)
//     }
//
//     React.useEffect(() => {
//         setVisiblePage(getVisiblePages(1, pages))
//         changePage(page + 1)
//     }, [pages])
//
//     const activePage: number = page + 1
//
//     return (
//         <div className="mt-5">
//             <div className="row">
//                 <div className="lhs-wrapper col-md-6">
//                     {dataLength
//                         ? `Showing ${page * pageSize + 1} - ${
//                             page + 1 === pages ? dataLength : (page + 1) * pageSize
//                         } of ${dataLength} results`
//                         : `No results found`}
//                 </div>
//                 <div className="rhs-wraper col-md-6">
//                     <div className="rhs-inner-wrapper d-flex justify-content-end align-items-center">
//                         <div className="previous-wrapper">
//                             <PaginationButton
//                                 onClick={() => {
//                                     if (activePage === 1) return
//                                     changePage(activePage - 1)
//                                 }}
//                                 disabled={activePage === 1}
//                             >
//                                 {previousText}
//                             </PaginationButton>
//                         </div>
//                         <div className="visiblePages-wrapper">
//                             {visiblePages.map((page, index, array) => {
//                                 return (
//                                     <PaginationButton
//                                         key={page}
//                                         className={activePage === page ? 'active' : null}
//                                         onClick={() => changePage(page)}
//                                     >
//                                         {array[index - 1] + 2 < page ? `...` : page}
//                                     </PaginationButton>
//                                 )
//                             })}
//                         </div>
//                         <div className="next-wrapper">
//                             <PaginationButton
//                                 onClick={() => {
//                                     if (activePage === pages) return
//                                     changePage(activePage + 1)
//                                 }}
//                                 disabled={activePage === pages}
//                             >
//                                 {nextText}
//                             </PaginationButton>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }
//
// export default Pagination
/* This example requires Tailwind CSS v2.0+ */
import {ChevronLeftIcon, ChevronRightIcon} from '@heroicons/react/solid'
import React from "react";
import {useTranslation} from "react-i18next";
import {usePaginationRange} from "../../utils/hooks/usePaginationRange";

interface Props {
    totalPageCount: number;
    pageSize: number;
    siblingCount?: number;
    currentPage: number;
    gotoPage: Function;
    setPageSize: Function;
    canPreviousPage: boolean;
    canNextPage: boolean;
}

export default function Pagination({
                                       currentPage,
                                       totalPageCount,
                                       siblingCount,
                                       pageSize,
                                       gotoPage,
                                       setPageSize,
                                       canNextPage,
                                       canPreviousPage
                                   }: Props) {

    const paginationRange = usePaginationRange({
        currentPage,
        totalPageCount,
        siblingCount,
        pageSize
    });
    const {t} = useTranslation('commons')

    // console.log(paginationRange)
    // If there are less than 2 times in pagination range we shall not render the component
    if (paginationRange!.length < 2) {
        return null;
    }
    return (
        <div className="bg-warm px-4 py-3 flex items-center justify-between sm:px-6">

            <div className="flex-1 flex justify-between sm:hidden">
                <button onClick={() => gotoPage(currentPage - 1)}
                        disabled={!canPreviousPage}
                        className={"relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"}
                >
                    Previous
                </button>
                <button onClick={() => gotoPage(currentPage + 1)}
                        disabled={!canNextPage}
                        className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                >
                    Next
                </button>
            </div>
            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                <div>
                    <div className="flex  lg:flex-row flex-col gap-3 lg:gap-2">
                        <span className="flex gap-1 items-center"> {t('pagination.text1')}
                        <select className="bg-warm-light"
                            value={pageSize}
                            onChange={e => {
                                setPageSize(Number(e.target.value))
                            }}
                        >
                            {[5, 10, 20].map(pageSize => (
                                <option key={pageSize} value={pageSize}>
                                   {pageSize}
                                </option>
                            ))}
                        </select>
                        </span>
                        <span className="lg:ml-6 text-gray-700">    Page <span
                            className="font-medium">{currentPage + 1}</span> {t('pagination.text2')} <span
                            className="font-medium">{totalPageCount}</span>
            </span>

                    </div>
                    {/*<p className="text-sm text-gray-700">*/}
                    {/*    Showing <span className="font-medium">1</span> to <span*/}
                    {/*    className="font-medium">{totalPageCount}</span> of{' '}*/}
                    {/*    <span className="font-medium">97</span> results*/}
                    {/*</p>*/}
                </div>
                <div>
                    <nav className="relative z-0 inline-flex rounded-md shadow-sm gap-1">
                        <button onClick={() => gotoPage(0)}
                                disabled={!canPreviousPage}
                                className="relative inline-flex items-center px-2 py-2 rounded-l-md bg-white font-medium text-gray-500 hover:bg-gray-50"
                        >
                            <span className="sr-only">Start</span>
                            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true"/>
                        </button>

                        {paginationRange?.map((pageNumber: any) => {

                            // If the pageItem is a DOT, render the DOTS unicode character
                            if (pageNumber === 'DOTS') {
                                return <span
                                    className="relative inline-flex items-center px-4 py-2 rounded bg-white text-sm font-medium text-gray-700">&#8230;</span>
                            }

                            // Render our Page Pills
                            return (
                                <button
                                    onClick={() => gotoPage(pageNumber - 1)}
                                    aria-current="page"
                                    className={(pageNumber - 1 === currentPage ? "z-10 bg-indigo-50 text-indigo-600 border-2 border-pink-600 " : "bg-white text-gray-500 hover:bg-gray-300 ") + "relative inline-flex items-center px-4 py-2 text-sm font-medium rounded-lg"}
                                >
                                    {pageNumber}
                                </button>

                            );
                        })}


                        <button onClick={() => gotoPage(totalPageCount - 1)}
                                disabled={!canNextPage}
                                className="relative inline-flex items-center px-2 py-2 rounded-r-md bg-white font-medium text-gray-500 hover:bg-gray-50"
                        >
                            <span className="sr-only">Last</span>
                            <ChevronRightIcon className="h-5 w-5" aria-hidden="true"/>
                        </button>
                    </nav>
                </div>
            </div>
        </div>
    )
}
