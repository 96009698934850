import React, {InputHTMLAttributes} from 'react';

const Input = ({className, label, ...props}: InputHTMLAttributes<HTMLInputElement> & { label?: string }) => {
    return (
        <label className="flex flex-col  gap-2 w-full">{label}
            <input className={`border-black border leading-5 p-2 ${className}`} {...props}/>
        </label>

    );
};

export default Input;
