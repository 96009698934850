import {ReactComponent as CoachingIcon} from "@icons/coaching.svg";
import {ReactComponent as UsageIcon} from "@icons/usage.svg";
import React from 'react';

import {useTranslation} from "react-i18next";
import {Link, Navigate, NavLink} from "react-router-dom";

import ISidebarMenu from "../../models/ISidebarMenu";
import LanguageSelector from "../../shared/components/LanguageSelector/LanguageSelector";
import {SidebarData} from "../../constants/data/SidebarData";
import Img from "../../shared/components/Img";
import useAuth from "../../utils/hooks/useAuth";

const SidebarItem = ({item}: { item: ISidebarMenu }) => {
    const {t} = useTranslation('layout')

    return <NavLink
        className={({isActive}) => {
            return (isActive ? "border-2 border-blue-400 border-l-4 border-l-blue rounded font-semibold text-blue" : "") + " flex gap-2 items-center my-2 hover:border-l-4 hover:border-l-blue h-10 hover:font-semibold hover:bg-gray-200"
        }}

        to={item.path ? item.path : ""}>
        {({isActive}) => (
            <>
                <i className="md:ml-10 ml-2 w-7 h-7"> {item.icon}</i>
                <span
                    className="w-full md:block hidden duration-300 opacity-0 md:opacity-100 translate-x-28 md:translate-x-0 md:overflow-auto overflow-hidden">
                    {item.title}
                    {/*{t(`sidebar.${item.title}`)} */}
                </span>
            </>
        )}

    </NavLink>
}

const Sidebar = () => {
    const {t} = useTranslation('sidebar')
    const {user} = useAuth()
    return (
        <div className="h-full w-12 md:w-64 m-0 flex flex-col bg-white justify-between shadow-md z-10 shrink-0">
            <div>
                <div className="p-2 md:p-5">
                    {/*<Img triggerPx={768} srcSmall={null} srcLarge={logoImg}/>*/}
                    <h2>Touchtab</h2>
                </div>
                <div className="mt-5 flex flex-col">
                    {SidebarData.map((item, index) => {
                        if (!(item.allowedRoles && user?.role && (item.allowedRoles?.indexOf(user.role) === -1))) {
                            return <SidebarItem key={index} item={item}/>
                        }
                    })}

                </div>
            </div>
            <div className="mb-10">
                {/*<SidebarItem item={{*/}
                {/*    title:  t('text6') ,*/}
                {/*    icon: <UsageIcon/>,*/}
                {/*    path: "settings",*/}
                {/*}}/>*/}

                <div className="flex gap-2 items-center my-3 pl-0 md:pl-7">
                    <LanguageSelector/>
                </div>
            </div>
        </div>
    );
};

export default Sidebar;
