import React, {useEffect, useState} from 'react';
import * as Yup from "yup";
import {Formik, FormikProps, FormikValues, Form, FormikHelpers} from "formik";
import InputFormik from "../../../shared/components/formik/InputFormik/InputFormik";
import TextareaFormik from "../../../shared/components/formik/TextareaFormik/TextareaFormik";
import SelectFormik from "../../../shared/components/formik/SelectFormik";
import InputFormikWithIcon from "../../../shared/components/formik/InputFormik/InputFormikWithIcon";
import {LinkIcon} from "@heroicons/react/outline";
import SubmitFormik from "../../../shared/components/formik/SubmitFormik/SubmitFormik";
import MyDateRange from '../../../shared/components/MyDateRange';
import moment from "moment";
import {CalendarSelectionType} from "../../../models";
import {GET, POST} from "../../../utils/lib/axios";
import {toast} from "react-toastify";
import {IMeta} from "../../../models/IMeta";
import Input from "../../../shared/components/form/Input";
import Textarea from "../../../shared/components/form/Textarea";
import Select from "react-select";
import SelectBox from "../../../shared/components/form/SelectBox";
import Button from "../../../shared/components/form/Button";
import useAuth from "../../../utils/hooks/useAuth";


const CheckInOut = () => {
    const [roomOptions, setRoomOptions] = useState([])
    const [typeOptions, setTypeOptions] = useState<any[]>([
        {id: 'presidential', name: 'Presidential'},
        {id: 'junior', name: 'Junior'},
        {id: 'standard', name: 'Standard'},
        {id: 'honeymoon', name: 'Honeymoon'},
        {id: 'bridal', name: 'Bridal'},
        {id: 'penthouse', name: 'Penthouse'}


    ])
    const {user} = useAuth()
    const [selectedRoom, setSelectedRoom] = useState<Record<string, any>>()
    const [selectedRoomType, setSelectedRoomType] = useState<Record<string, any>>()

    const [calState, setCalState] = useState<CalendarSelectionType[]>([
        {
            startDate: moment().toDate(),
            // startDate: new Date(),
            endDate: moment().toDate(),
            key: 'selection'
        }
    ]);

    const [guest, setGuest] = useState<Record<string, any>>(
        {
            name: '',
            email: '',
            phone: ''
        }
    );
    const handleInputChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        const {name, value} = event.target;
        setGuest((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    const getRooms = () => {
        GET({path: "/hotels/{id}/rooms"}, {id:  user?.hotel?.id}, {
            checkIn: moment(calState[0].startDate!).format('YYYY-MM-DD'),
            checkOut: moment(calState[0].endDate!).format('YYYY-MM-DD'),
            roomType: selectedRoomType?.id
        })
            .then(r => {
                console.log(r.data);
                setRoomOptions(r.data)
            })

            .catch(e => toast.error(e.response.data.message));
    }
    const save = () => {
        const body = {
            user: {
                name: guest.name,
                email: guest.email,
                phone: guest.phone,
            },
            checkIn: moment(calState[0].startDate!).format('YYYY-MM-DD'),
            checkOut: moment(calState[0].endDate!).format('YYYY-MM-DD'),
        }
        POST({path: "/hotels/{hotelId}/rooms/{roomId}/checkIn"}, body, {hotelId: 1, roomId: selectedRoom?.id},)
            .then(r => {
                toast.success(r.data.message)

            }).catch(e => toast.error(e.response.data.message));
    }

    useEffect(() => {
        getRooms()
    }, [selectedRoom, selectedRoomType])

    return (
        <div className="p-10 flex-col flex gap-5">


            <div className="flex gap-5 items-center justify-between w-full">

                <div className="flex flex-col gap-2">
                    <span className="font-medium">Room Type</span>
                    <SelectBox onChange={(data: any) => setSelectedRoomType(data)} isMulti={false}
                               name="type" labelKey="name" valueKey="id"
                               options={typeOptions}/>
                </div>
                <div className="flex flex-col gap-2">
                    <span className="font-medium">Date</span>
                    <MyDateRange state={calState} setState={setCalState}/>
                </div>
                <div className="flex flex-col gap-2">
                    <span className="font-medium">Room</span>
                    <SelectBox onChange={(data: any) => setSelectedRoom(data)} isMulti={false} name="room"
                               labelKey="number" valueKey="id"
                               options={roomOptions}/>
                </div>

            </div>
            <br/>
            <br/>
            <Input value={guest.name}
                   onChange={handleInputChange} placeholder={"Name"}
                   label={"Name"} name={"name"}/>

            <Input value={guest.email}
                   onChange={handleInputChange} placeholder={"Email"}
                   label={"Email"} name={"email"}/>
            <Textarea value={guest.address}
                      onChange={handleInputChange} rows={5} placeholder={"Address"}
                      label={"Address"} name={"address"}/>
            <br/>
            <br/>
            <br/>
            <Button onClick={() => save()} className="dashboard-button bg-black py-3 justify-center ml-auto">
                Save
            </Button>
        </div>
    );
};

export default CheckInOut;
