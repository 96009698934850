import Hotels from "../dashboard/pages/Hotels";
import Services from "../dashboard/pages/Services";
import {ERole, ERouteType} from "../../constants/enums";
import Partners from "../dashboard/pages/Partners";
import MeetingRooms from "../dashboard/pages/MeetingRooms";
import PartnerAdmins from "../dashboard/pages/PartnerAdmins";
import FoodMenu from "../dashboard/pages/FoodMenu";
import {IRoute} from "../../models/IRoute";
import HotelAdmins from "../dashboard/pages/HotelAdmins";
import Restaurants from "../dashboard/pages/Restaurants";
import {DashboardLayout, PageLayout} from "../../layouts";
import Intro from "./pages/Intro";
import Home from "./pages/Home";
import Wifi from "./pages/Wifi";
import Restaurant from "./pages/Restaurant";
import GuestLayout from "../../layouts/GuestLayout";
import Menu from "./pages/Menu";
import BabySitter from "./pages/BabySitter";
import BabySitterBooking from "./pages/BabySitterBooking";
import MeetingRoom from "./pages/MeetingRoom";
import MeetingRoomBooking from "./pages/MeetingRoomBooking";
import Boat from "./pages/Boat";
import BoatBooking from "./pages/BoatBooking";
import Activities from "./pages/Activities";
import ThankYou from "./pages/ThankYou";

const GuestRoutes: IRoute[] = [
    {
        path: "/",
        layout: GuestLayout,
        routeType: ERouteType.public,
        routes: [
            {
                path: "home",
                component: Home,
                allowedRoles: [ERole.Guest],
                routeType: ERouteType.public,
                props: {navTitle: 'Home'}
            },

            {
                path: "wifi",
                component: Wifi,
                allowedRoles: [ERole.Guest],
                routeType: ERouteType.public,
                props: {navTitle: 'Wifi'}
            },
            {
                path: "restaurants",
                component: Restaurant,
                allowedRoles: [ERole.Guest],
                routeType: ERouteType.public,
                props: {navTitle: 'Restaurants'}
            },
            {
                path: "restaurants/:id",
                component: Menu,
                allowedRoles: [ERole.Guest],
                routeType: ERouteType.public,
                props: {navTitle: 'Food Menu'}
            },
            {
                path: "baby-sitters",
                component: BabySitter,
                allowedRoles: [ERole.Guest],
                routeType: ERouteType.public,
                props: {navTitle: 'Baby Care'}
            },
            {
                path: "baby-sitters/:id",
                component: BabySitterBooking,
                allowedRoles: [ERole.Guest],
                routeType: ERouteType.public,
                props: {navTitle: 'Baby Care Reservation'}
            },
            {
                path: "meeting-rooms",
                component: MeetingRoom,
                allowedRoles: [ERole.Guest],
                routeType: ERouteType.public,
                props: {navTitle: 'Meeting Rooms'}
            },
            {
                path: "meeting-rooms/:id",
                component: MeetingRoomBooking,
                allowedRoles: [ERole.Guest],
                routeType: ERouteType.public,
                props: {navTitle: 'Meeting Room Reservation'}
            },
            {
                path: "activities",
                component: Activities,
                allowedRoles: [ERole.Guest],
                routeType: ERouteType.public,
                props: {navTitle: 'Activities'}
            },
            {
                path: "boats",
                component: Boat,
                allowedRoles: [ERole.Guest],
                routeType: ERouteType.public,
                props: {navTitle: 'Boats'}
            },
            {
                path: "boats/:id",
                component: BoatBooking,
                allowedRoles: [ERole.Guest],
                routeType: ERouteType.public,
                props: {navTitle: 'Boat Reservation'}
            },
       {
                path: "thank-you",
                component: ThankYou,
                allowedRoles: [ERole.Guest],
                routeType: ERouteType.public,
                props: {navTitle: 'Thank you'}
            },
        ]
    },
    {
        path: "/",
        layout: PageLayout,
        routeType: ERouteType.public,
        routes: [
            {
                path: "intro",
                component: Intro,
                allowedRoles: [ERole.Guest],
                routeType: ERouteType.public,
                props: {navTitle: ''}
            },


        ]

    },

];

export default GuestRoutes;
