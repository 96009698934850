import React, {useEffect, useState} from 'react';
import useAuth from "../../utils/hooks/useAuth";
import storage from "../../services/storage/localStorage";
import {DropdownWrapper} from "../../shared/components";
import UserDropdownItemWrapper from "./UserDropdownItemWrapper";

const GuestNavbar = ({navTitle}: { navTitle?: string }) => {
    const {logout, user} = useAuth()



    useEffect(() => {

        }, []
    )

    return (
        <nav className="flex items-center justify-between px-6 py-3 bg-black text-white">
            <div className="text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap">
                <h4 className="flex gap-4 ">  <span className="">&#127976;</span>  {navTitle}</h4>
            </div>
            <div className="flex items-center gap-3">

                <DropdownWrapper
                    button={
                        <div
                            className="cursor-pointer flex items-center justify-between gap-1.5 group">
                            <img
                                className="w-10 h-10 rounded-full object-cover group-hover:ring-4 group-hover:ring-red"
                                src={user?.imageUrl || "https://avatars.dicebear.com/api/micah/1.jpg"}/>
                            <span
                                className=""> Hi,</span>
                            <span
                                className="hidden sm:block"> {user?.name} !</span>
                            <svg className="ml-1 w-4 h-4" aria-hidden="true" fill="currentColor"
                                 viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd"
                                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                      clip-rule="evenodd"></path>
                            </svg>
                        </div>
                    }
                    body={<>
                        <div className="py-1">
                            {/*<UserDropdownItemWrapper userRole={user?.role}>*/}
                            {/*    <Link to="profile"*/}
                            {/*          className="text-gray-700 block px-4 py-2 text-sm w-full">{t('navbar.text3')}*/}
                            {/*    </Link>*/}
                            {/*</UserDropdownItemWrapper>*/}
                            {/*todo:Include with the Calendar update*/}
                            {/*<UserDropdownItemWrapper userRole={user?.role}>*/}
                            {/*    <Link to="settings"*/}
                            {/*          className="text-gray-700 block px-4 py-2 text-sm w-full">{t('navbar.text4')}*/}
                            {/*    </Link>*/}
                            {/*</UserDropdownItemWrapper>*/}
                            <hr/>
                            <UserDropdownItemWrapper userRole={user?.role}>
                                <button onClick={() => logout()}
                                        className="text-gray-700 block px-4 py-2 text-sm w-full">Sign Out
                                </button>
                            </UserDropdownItemWrapper>


                        </div>

                    </>
                    }/>
            </div>

        </nav>
    );
};



export default GuestNavbar;
