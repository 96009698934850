import React, {useEffect, useState} from 'react';
import useAuth from "../../../utils/hooks/useAuth";
import AccountSetupMultiTemplateWithDropDown from "../../../shared/components/AccountSetupMultiTemplateWithDropDown";
import {GET, POST} from "../../../utils/lib/axios";
import {toast} from "react-toastify";

const MeetingRooms = () => {
    const [data, setData] = useState({meetingRooms: []})
    const {user} = useAuth()

    const [options, setOptions] = useState<Record<string, any>[]>([{}]);

    const loadOptions = () => {
        GET({path: "/partners"})
            .then(r => {
                setOptions(r.data.map(((item: any, i: number) => {
                        console.log((item));
                        return {label: item.name, value: item.id}
                    }))
                )

            }).catch(e => toast.error(e.response.data.message));
    }
    useEffect(() => {
        loadOptions()
    }, [])

    const handleSave = (data: Record<string, any>) => {

        const body = {
            name: data.name,
            place: data.place,
            capacity: data.capacity,
            price:data.price,
            partner: data.dropdownId ? {id:data.dropdownId,name:data.dropdownLabel} : undefined
        }
        POST({path: "/hotels/{id}/meetingRooms"}, body, {id: user?.hotel?.id})
            .then(r => {
                toast.success(r.data.message)
                loadData()

            }).catch(e => toast.error(e.response.data.message));
    }
    const handleDelete = (data: Record<string, any>) => {


    }
    const loadData = () => {
        GET({path: "/hotels/{id}/meetingRooms"}, {id: user?.hotel?.id}).then(r => {
            console.log(r);
            setData({
                meetingRooms: r.data.map(((item: any, i: number) => {
                    return {
                        no: i + 1,
                        id: item.id,
                        name:item.name,
                        dropdownId: item.partner?.id,
                        dropdownLabel: item.partner?.name,
                        place: item.place,
                        capacity: item.capacity,
                        price: item.price,
                     }
                }))
            })

        }).catch(e => toast.error(e.response.data.message));
    }
    useEffect(() => {
        loadData()
    }, [])
    return (
        <div className="p-5">
            <AccountSetupMultiTemplateWithDropDown
                options={options}
                keyArr={[
                    {key: 'name', header: 'Name'},
                    {key: 'place', header: 'Place'},
                    {key: 'capacity', header: 'Capacity'},
                    {
                    key: 'price',
                    header: 'Price per hour ($)'
                }]}
                key="s1" data={data?.meetingRooms} name={"meetingRooms"}
                handleSave={handleSave}
                // handleDelete={handleDelete}
                setData={setData}
                title=""
                des=""
                addingTitle=""
                dropdownHeader="Partners"/>
        </div>
    );
}
export default MeetingRooms;
