import React, {useEffect, useState} from 'react';
import useAuth from "../../../utils/hooks/useAuth";
import AccountSetupMultiTemplateWithDropDown from "../../../shared/components/AccountSetupMultiTemplateWithDropDown";
import {GET, POST} from "../../../utils/lib/axios";
import {toast} from "react-toastify";

const PartnerAdmins = () => {
    const [data, setData] = useState({partnerAdmins: []})
    const {user} = useAuth()

    const [options, setOptions] = useState<Record<string, any>[]>([{}]);

    const loadOptions = () => {
        GET({path: "/partners"})
            .then(r => {
                setOptions(r.data.map(((item: any, i: number) => {
                        console.log((item));
                        return {label: item.name, value: item.id}
                    }))
                )

            }).catch(e => toast.error(e.response.data.message));
    }
    useEffect(() => {
        loadOptions()
    }, [])

    const handleSave = (data: Record<string, any>) => {
        const body = {
            name: data.contactPerson,
            email: data.email,
            phone: data.phone
        }
        POST({path: "/partners/{id}/admin"}, body, {id: data.dropdownId})
            .then(r => {
                toast.success(r.data.message)
                loadData()

            }).catch(e => toast.error(e.response.data.message));
    }
    const handleDelete = (data: Record<string, any>) => {


    }
    const loadData = () => {
        GET({path: "/users/partnerAdmins"}).then(r => {
            setData({
                partnerAdmins: r.data.map(((item: any, i: number) => {
                    return {
                        no: i + 1,
                        id: item.id,
                        dropdownId: item.partner.id,
                        dropdownLabel: item.partner.name,
                        email: item.email,
                        contactPerson: item.name,
                        phone: item.phone
                    }
                }))
            })

        }).catch(e => toast.error(e.response.data.message));
    }
    useEffect(() => {
        loadData()
    }, [])
    return (
        <div className="p-5">
            <AccountSetupMultiTemplateWithDropDown
                options={options}
                keyArr={[{key: 'contactPerson', header: 'Contact Person'}, {
                    key: 'email',
                    header: 'Email'
                }, {key: 'phone', header: 'Phone Number'},]}
                key="s1" data={data?.partnerAdmins} name={"partnerAdmins"}
                handleSave={handleSave}
                // handleDelete={handleDelete}
                setData={setData}
                title=""
                des=""
                addingTitle=""
                dropdownHeader="Partner"/>
        </div>
    );
};


export default PartnerAdmins;
