import React from 'react';

import {Column, TableOptions, useGlobalFilter, usePagination, useSortBy, useTable} from "react-table";
import Pagination from "./Pagination";

interface Props extends TableOptions<object> {
    columns: Column [];
    data: any[];
    onRowClick?: (data: any) => void;
}

const Table = ({columns, data, onRowClick, ...rest}: Props) => {


    const {
            getTableProps, getTableBodyProps, headerGroups,
            //new
            page, // Instead of using 'rows', we'll use page,
            // which has only the rows for the active page

            // The rest of these things are super handy, too ;)
            canPreviousPage,
            canNextPage,
            pageOptions,
            pageCount,

            gotoPage,
            nextPage,
            previousPage,
            setPageSize,

            prepareRow,
            state,
            preGlobalFilteredRows,
            setGlobalFilter,
        } = useTable({
                columns,
                data,
                ...rest

            },
            useGlobalFilter, useSortBy, usePagination,
        )
    ;
    // console.log(pageOptions.length,pageCount)
    return (
        <div className="pb-5">
            <table {...getTableProps()}
                   className="relative overflow-auto block md:table table-auto w-full [&>*>tr>td]:px-6 [&>*>tr>th]:font-normal  [&>*>tr>th]:px-6 rounded  border-collapse">
                <thead className="bg-blue text-white text-left [&>tr>th]:py-4
                tracking-wider">
                {headerGroups.map((headerGroup: any) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column: any) => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                {column.render("Header")}
                                <span>
                    {column.isSorted
                        ? column.isSortedDesc
                            ? ' ▼'
                            : ' ▲'
                        : ''}
                  </span>
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>

                <tbody {...getTableBodyProps()} className="text-left [&>tr>th]:py-4 ">
                {page.map((row: any, i: number) => {
                    prepareRow(row);
                    return (
                        <tr onClick={() => onRowClick ? onRowClick(row.original) : undefined}
                            className={"hover:bg-gray-300 border border-b-myGray-lightBlue bg-white [&>td]:py-3 first:[&>td]:first:rounded-tl last:[&>td]:first:rounded-tr first:[&>td]:last:rounded-bl last:[&>td]:last:rounded-br"} {...row.getRowProps()}>
                            {row.cells.map((cell: any) => {
                                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                            })}
                        </tr>
                    );
                })}


                </tbody>
            </table>


            <Pagination canNextPage={canNextPage} canPreviousPage={canPreviousPage} setPageSize={setPageSize}
                        pageSize={state.pageSize}
                        gotoPage={gotoPage} totalPageCount={pageCount} currentPage={state.pageIndex}/>
        </div>
    );
};

export default Table;
