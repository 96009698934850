import React, {useEffect, useState} from 'react';
import useAuth from "../../../utils/hooks/useAuth";
import AccountSetupMultiTemplateWithDropDown from "../../../shared/components/AccountSetupMultiTemplateWithDropDown";
import AccountSetupMultiTemplateWithDropDown2 from "../../../shared/components/AccountSetupMultiTemplateWithDropDown2";

const Services = () => {
    const [data, setData] = useState({services: []})
    const {user} = useAuth()

    const [options, setOptions] = useState<Record<string, any>[]>([{}]);


    const loadOptions = () => {

    }
    useEffect(() => {
        loadOptions()
    }, [])

    const handleSave = (data: Record<string, any>) => {

    }
    const handleDelete = (data: Record<string, any>) => {


    }
    const loadData = () => {

    }
    useEffect(() => {
        loadData()
    }, [])
    return (
        <div className="p-5">
            <AccountSetupMultiTemplateWithDropDown2
                options={options}
                options2={options}
                keyArr={[{key: 'name', header: 'Name'}, {key:'description',header:'Description'}]}
                key="s1"
                data={data?.services}
                name={"services"}
                handleSave={handleSave}
                handleDelete={handleDelete}
                setData={setData}
                title=""
                des=""
                addingTitle=""
                dropdownHeader="Partner"
                dropdownHeader2="Service Category"
            />
        </div>
    );
};

export default Services;
