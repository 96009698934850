import React, {useState} from 'react';
import Sidebar from "./components/Sidebar";
import {Outlet} from "react-router-dom";
import Navbar from "./components/Navbar";


const DashboardLayout = () => {
    const [navTitle, setNavTitle] = useState();

    return (
        <div className="flex h-screen w-screen">
            <Sidebar/>
            <div className="bg-warm flex-auto h-full overflow-auto flex flex-col">
                <Navbar navTitle={navTitle} />
                <main className="bg-warm m-0 flex-auto">
                    <Outlet context={{navTitle,setNavTitle}}/>
                </main>
            </div>
        </div>
    );
};

export default DashboardLayout;
