import React from 'react';
interface Props {
    name:string;
    imageUrl:string
    description?:string
}
const ActivityCard = (props:Props) => {
    return (
        <div style={{backgroundImage: `url(${props.imageUrl  })`}}
             className="bg-bottom flex flex-col gap-2 justify-between transition-all h-full hover:border-4 border-white bg-cover relative rounded-2xl shadow overflow-hidden ">
            <div className="text-white backdrop-blur-sm font-medium py-2 px-5 w-full flex flex-col gap-2">
                <h2>{props.name}</h2>
                {/*<span>Place: {item.place}</span>*/}
                <p>{props.description}</p>
            </div>
            {/*<div className="text-white bg-black font-normal py-2 px-5 w-full flex flex-col gap-2">*/}
            {/*    <span>Price per person : ${item.price}</span>*/}
            {/*    <span className="flex gap-2 justify-between">*/}
            {/*        <span>Total Seats : {item.seats}</span>*/}
            {/*     <span>Reserved : {item.reserved}</span>*/}
            {/*    </span>*/}
            {/*    <span className="flex gap-2 justify-between">*/}
            {/*        /!*<span>Start: {moment(item.startTime).format('YYYY-MM-DD : HH')}h</span>*!/*/}
            {/*     /!*<span>End: {moment(item.endTime).format('YYYY-MM-DD : HH')}h</span>*!/*/}
            {/*    </span>*/}


            {/*</div>*/}
        </div>
    );
};

export default ActivityCard;
