import React, {useEffect, useState} from 'react';
import Modal from "../../../shared/components/Modal";

import {useParams, useSearchParams} from "react-router-dom";
import {GET} from "../../../utils/lib/axios";
import {toast} from "react-toastify";
import {IMeta} from "../../../models/IMeta";
import SelectBox from "../../../shared/components/form/SelectBox";
import {ActionMeta} from "react-select";
import Button from "../../../shared/components/form/Button";
import {capitalizeSentence} from "../../../utils/helpers/str";
import AddFoodForm, {IFood} from "../../dashboard/forms/AddFoodForm";
import FoodCard from "../../dashboard/pages/FoodCard";
import Cart, {ICart} from "../components/Cart";
import homeImg from "../../../assets/img/restaurant.png";
import {ShoppingCartIcon} from "@heroicons/react/solid";

const Menu = () => {
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [searchParams, setSearchParams] = useSearchParams()
    const {id} = useParams()
    const [foods, setFoods] = useState<IFood[]>([]);
    const [cart, setCart] = useState<Record<any, any>>();
    const [selectedFood, setSelectedFood] = useState<IFood>();
    const [foodCategories, setFoodCategories] = useState<IMeta[]>([]);
    const [selectedFoodCategory, setSelectedFoodCategory] = useState<IMeta>();
    const loadFoodCategories = () => {
        GET({path: "/meta/foodCategories"}).then(r => {
            console.log(r);
            setFoodCategories(r.data.map(((item: any, i: number) => {
                    return {
                        id: item.id,
                        name: item.name,
                    }
                }))
            )

        }).catch(e => toast.error(e.response.data.message));
    }
    const loadFoods = () => {

        GET({path: "/hotels/{hotelId}/foodMenu"}, {hotelId: 1}, {
            restaurantId: id,
            foodCategoryId: selectedFoodCategory?.id
        })
            .then(r => {
                // setFoods(r.data)
                setFoods(r.data.map(((item: any, i: number) => {
                        return {
                            name: item.name,
                            id: item.id,
                            price: item.price,
                            description: item.description,
                            category: {id: item.foodCategory.id, name: item.foodCategory.name},
                            type: {id: i, name: capitalizeSentence(item.type)},
                            imageUrl: item.imageUrl,

                        }
                    }))
                )

            })
        // .catch(e => toast.error(e.response.data.message));
    }
    useEffect(() => {
        loadFoodCategories()
    }, [])
    useEffect(() => {
        loadFoods()
    }, [selectedFoodCategory])

    return (
        <div className="flex h-full relative">
            <div className="p-2 w-24 flex flex-col  border-gray-400 border-r shrink-0">
                <ul className="flex flex-col gap-2 cursor-pointer">
                    <li onClick={() => setSelectedFoodCategory(undefined)}
                        className={`${selectedFoodCategory == null ? 'bg-blue text-white ' : 'hover:border-blue border-transparent border-2 '}  rounded py-1 px-2`}>All
                    </li>
                    {foodCategories.map((category, i) =>
                        <li onClick={() => setSelectedFoodCategory(category)}
                            className={`${selectedFoodCategory?.id == category.id ? 'bg-blue text-white ' : 'hover:border-blue border-transparent border-2 '}  rounded py-1 px-2`}>{category.name}</li>
                    )}
                </ul>
            </div>
            <div className="flex-auto flex flex-col">
                <div className="p-2 flex border-b border-gray-400 w-full justify-between">
                    <Button className="ml-auto"
                            onClick={() => {
                                setSelectedFood(undefined)
                                setModalOpen(true)
                            }}> <ShoppingCartIcon className="w-8 h-8"/> </Button>
                </div>
                <div className="grid grid-cols-3 gap-5 p-5 flex-auto ">
                    {foods.map((food, i) =>
                        <div
                            className={`relative after:absolute after:rounded-full after:-top-2 after:-left-2 after:z-10 after:shadow-2xl after:bg-red after:w-8 after:h-8 after:border-white after:border-4 ${cart?.hasOwnProperty(String(food.id)) ? 'after:block ' : 'after:hidden'}`}
                            onClick={() => {
                                setCart(prevState => {
                                    const id = String(food.id);
                                    if (prevState && prevState[id]) {
                                        // Increment the quantity of an existing item.
                                        return {
                                            ...prevState,
                                            [id]: {
                                                ...prevState[id],
                                                quantity: prevState[id].quantity + 1
                                            }
                                        };
                                    } else {
                                        // Add a new item to the cart.
                                        return {
                                            ...prevState,
                                            [id]: {
                                                food: food,
                                                quantity: 1
                                            }
                                        };
                                    }
                                });
                            }}>
                            <div className="absolute right-5 top-5 font-bold text-5xl p-1 cursor-pointer">
                                +
                            </div>
                            <FoodCard food={food}/>
                        </div>
                    )}


                </div>
                <Modal
                    title={''}
                    isOpen={modalOpen}
                    onClose={setModalOpen}
                    className="w-8/12 bg-warm-light"
                >
                    <Cart items={cart}/>
                </Modal>
            </div>
        </div>
    );
};

export default Menu;
