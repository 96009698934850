import {DashboardLayout} from "../../../layouts";
import {IRoute} from "../../../models/IRoute";
import {ERole, ERouteType} from "../../../constants/enums";
import Hotels from "../pages/Hotels";
import HotelAdmins from "../pages/HotelAdmins";
import Services from "../pages/Services";
import Partners from "../pages/Partners";
import PartnerAdmins from "../pages/PartnerAdmins";
import MeetingRooms from "../pages/MeetingRooms";
import Restaurants from "../pages/Restaurants";
import FoodMenu from "../pages/FoodMenu";
import CheckInOut from "../pages/CheckInOut";

const DashboardRoutes: IRoute[] = [
    {
        path: "/dashboard",
        layout: DashboardLayout,
        routeType: ERouteType.public,
        routes: [
            {
                path: "hotels",
                component: Hotels,
                allowedRoles: [ERole.SuperAdmin],
                routeType: ERouteType.private,
                props: {navTitle: 'Hotels'}
            },
            {
                path: "hotel-admins",
                component: HotelAdmins,
                allowedRoles: [ERole.SuperAdmin],
                routeType: ERouteType.private,
                props: {navTitle: 'Hotel Admins'}
            },
            {
                path: "services",
                component: Services,
                allowedRoles: [ERole.SuperAdmin, ERole.HotelAdmin],
                routeType: ERouteType.private,
                props: {navTitle: 'Services'}
            },
            {
                path: "partners",
                component: Partners,
                allowedRoles: [ERole.SuperAdmin, ERole.HotelAdmin],
                routeType: ERouteType.private,
                props: {navTitle: 'Partners'}
            },

            {
                path: "partner-admins",
                component: PartnerAdmins,
                allowedRoles: [ERole.SuperAdmin, ERole.HotelAdmin],
                routeType: ERouteType.private,
                props: {navTitle: 'Partner Admins'}
            },
            {
                path: "meeting-rooms",
                component: MeetingRooms,
                allowedRoles: [ERole.PartnerAdmin, ERole.HotelAdmin],
                routeType: ERouteType.private,
                props: {navTitle: 'Meeting Rooms'}
            },
            {
                path: "restaurants",
                component: Restaurants,
                allowedRoles: [ERole.PartnerAdmin, ERole.HotelAdmin],
                routeType: ERouteType.private,
                props: {navTitle: 'Restaurants'}
            },
            {
                path: "food-menu",
                component: FoodMenu,
                allowedRoles: [ERole.PartnerAdmin, ERole.HotelAdmin],
                routeType: ERouteType.private,
                props: {navTitle: 'Food Menu'}
            },
            {
                path: "check-in-out",
                component: CheckInOut,
                allowedRoles: [ERole.HotelAdmin],
                routeType: ERouteType.private,
                props: {navTitle: 'Check In'}
            },

        ]

    },

];

export default DashboardRoutes;
