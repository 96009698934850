import React from 'react';
import homeImg from "../../../assets/img/baby.png";

const BabySitterCard = ({babySitter}: { babySitter: Record<string, any> }) => {
    return (
        <div style={{backgroundImage: `url(${babySitter.imageUrl  || homeImg})`}}
             className="flex flex-col gap-2 justify-between transition-all h-full hover:border-4 border-white bg-cover relative rounded-2xl shadow overflow-hidden ">
            <div className="text-white backdrop-blur-sm font-medium py-2 px-5 w-full flex flex-col gap-2">
                <h2>{babySitter.name}</h2>
                <span>Place: {babySitter.place}</span>
                <p>{babySitter.description}</p>


            </div>
            <div className="text-white bg-black font-medium py-2 px-5 w-full flex flex-col gap-2">

                <span>Price per hour : ${babySitter.price}</span>

                <span className="flex gap-2 justify-between">
                    <span>Capacity : {babySitter.capacity}</span>
                 <span>Reserved : {babySitter.reserved}</span>
                </span>
                <span className="flex gap-2 justify-between">
                    <span>Open: 8:00 am</span>
                 <span>Close: 6:00 pm</span>
                </span>


            </div>
        </div>
    );
};

export default BabySitterCard;
