import React from 'react';
import {Link} from "react-router-dom";
import BoatCard from "../components/BoatCard";
import ActivityCard from "../components/ActivityCard";
import homeImg from "../../../assets/img/boat.png";

const Activities = () => {
    return (
        <div className="p-5 grid grid-cols-3 grid-rows-2 h-full gap-5 ">

            <Link to={`/boats`}
                  className={``}>
                <ActivityCard name="Boat" imageUrl={homeImg}
                />
            </Link>
            <Link to={`/boats`}
                  className={``}>
                <ActivityCard name="Runing Clubs" imageUrl={homeImg}
                />
            </Link>


            <Link to={`/boats`}
                  className={``}>
                <ActivityCard name="Cycling Clubs" imageUrl={homeImg}
                />
            </Link>
            <Link to={`/boats`}
                  className={``}>
                <ActivityCard name="Cooking Clubs" imageUrl={homeImg}
                />
            </Link>
            <Link to={`/boats`}
                  className={``}>
                <ActivityCard name="Toast Masters" imageUrl={homeImg}
                />
            </Link>
            <Link to={`/boats`}
                  className={``}>
                <ActivityCard name="Rock Climbing Club" imageUrl={homeImg}
                />
            </Link>


        </div>
    );
};

export default Activities;
