import React from 'react';

interface Props {
    name: string;
    type: string;
    imageUrl?: string
    openTime?: string
    closeTime?: string
    description?: string
    capacity?: number
}

const RestaurantCard = (props: Props) => {
    return (
        <div style={{backgroundImage: `url(${props.imageUrl})`}}
             className="flex flex-col gap-2 justify-between transition-all h-full hover:border-4 border-white bg-cover relative rounded-2xl shadow overflow-hidden ">
            <div className="text-white backdrop-blur-sm font-medium py-2 px-5 w-full flex flex-col gap-2">
                <h2>{props.name}</h2>
                <span>{props.type}</span>
                <p>{props.description}</p>


            </div>
            <div className="text-white bg-black py-2 px-5 w-full flex flex-col gap-2">

                <span>Capacity : {props.capacity}</span>
                <span className="flex gap-2 justify-between">
                    <span>Open: {props.openTime}h</span>
                 <span>Close: {props.closeTime}h</span>
                </span>


            </div>
        </div>
    );
};

export default RestaurantCard;
