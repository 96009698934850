import React from 'react';
import boatImg from "../../../assets/img/boat.png";
import {LocationMarkerIcon, SunIcon} from "@heroicons/react/outline";
import {ClockIcon} from "@heroicons/react/solid";
import Button from "../../../shared/components/form/Button";
import {Link} from "react-router-dom";

const BoatBooking = () => {
    return (
        <div style={{backgroundImage: `url(${boatImg})`}}
             className="text-gold flex flex-col relative min-h-screen bg-cover bg-top">
            <div
                className="absolute top-5 right-5 flex gap-5 flex-col p-5 rounded-md border-2 border-gold bg-[rgba(255,255,255,0.4)] ">
                <div className="flex gap-4">
                    <SunIcon className="w-12 h-12"/>
                    <h3>20% off</h3>
                </div>
                <div className="">
                    Exclusive Accor Membership Offer
                </div>

            </div>
            <div
                className="text-black absolute bottom-5 left-5 flex flex-col rounded-xl border-2 border-gold overflow-hidden">
                <div
                    className="flex gap-5 flex-col p-5 border-b-2 border-gold bg-[rgba(255,255,255,0.7)] ">

                    <div className="flex gap-4">
                        <LocationMarkerIcon className="w-5 h-5"/>
                        <h5 className="font-bold">Bateaux-Mouches -
                            Dinner Cruise</h5>
                    </div>
                    <div className="flex gap-4">
                        <ClockIcon className="w-5 h-5"/>
                        <h5>6pm/ 8.30pm</h5>
                    </div>
                </div>
                <div
                    className="flex gap-5 flex-col p-5 bg-[rgba(248,248,248,0.5)] ">


                    <div className="flex gap-4 items-center justify-between">

                        <h5 className="font-bold">Details</h5>
                        <Link to="/thank-you">
                        <Button className="bg-gold border-2 border-white">Get Tickets</Button>

                        </Link>
                    </div>
                </div>


            </div>

        </div>
    );
};

export default BoatBooking;
