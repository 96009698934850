import React from 'react';
import introImg from '../../../assets/img/intro.png'
import logoImg from '../../../assets/img/logo.png'
import middleImg from '../../../assets/img/intro-middle.png'
import {Link} from "react-router-dom";

const Intro = () => {

    return (
        <div style={{backgroundImage: `url(${introImg})`}} className="min-h-screen bg-cover bg-center">
            <span className="absolute bottom-5 right-5 text-white">Powered by <b>Touch Tab</b></span>
            <div className="">
                <div style={{backgroundImage: `url(${middleImg})`}}
                     className="text-white p-8 m-auto bg-cover bg-no-repeat bg-bottom w-[480px] h-[550px] flex flex-col gap-10 justify-center items-center">
                    <img className="h-28" src={logoImg}/>
                    <h4 className="text-center py-8 px-12">Personalize your hotel experience <br/> with us </h4>
                    <Link to="/auth/login" className="guest-button py-4 px-12 font-medium tracking-widest">LET’S GET STARTED</Link>
                </div>
            </div>
        </div>
    );
};

export default Intro;
