import React from 'react';
import {IFood} from "../forms/AddFoodForm";
import {StarIcon} from "@heroicons/react/solid";

const FoodCard = ({food}: { food: IFood }) => {
    console.log(food);
    return (
        <div className="hover:border-blue border-transparent border-4 p-3 pb-5 flex flex-col gap-3 justify-center items-center rounded bg-white h-full shadow-md">
            <img className="rounded  h-full w-full shadow "
                 src={food?.imageUrl}/>
            <div className="">
                <h4>{food?.name}</h4>
                <p className="mx-auto flex gap-2 my-2 items-center"> <StarIcon className="w-4 h-4"/>
                    {food?.rate} 4.8</p>
            </div>
            <h5>Category: {food?.category?.name}</h5>
            <h5 className="italic text-sky-600">~ {food?.type?.name} ~</h5>
            <div className="flex gap-2 justify-evenly w-full">
                <div className="flex flex-col items-center">
                    <p className="text-gray-400 font-extralight">Item No</p>
                    <p>{food?.id}</p>
                </div>
                <div className="flex flex-col items-center">
                    <p className="text-gray-400 font-extralight">Price</p>
                    <p>${food?.price}</p>
                </div>
                <div className="flex flex-col items-center">
                      <p className="text-gray-400 font-extralight">Orders</p>
                      <p>345</p>
                  </div>
            </div>
        </div>
    );
};

export default FoodCard;
