import React from 'react';
import bgImg from "../../../assets/img/bgwifi.png";
import {SunIcon} from "@heroicons/react/outline";
import logoImg from "../../../assets/img/logo.png";
import HomeNavigator from "../components/HomeNavigator";

const Wifi = () => {
    return (
        <div style={{backgroundImage: `url(${bgImg})`}} className="relative min-h-screen bg-cover bg-bottom text-white">
            <div className="absolute top-8 left-8">
                <h2>19:54</h2>
                <span className="flex gap-2 font-extralight items-center"><h2>17&deg;</h2> OUTSIDE <SunIcon
                    className="w-8"/> </span>
            </div>
            <div className="w-2/3 m-auto flex flex-col gap-5 items-center">
                <br/>
                <br/>
                <img className="h-28" src={logoImg}/>
                <h2 className="text-center py-8 px-12">Plug into free Wi-Fi during your stay</h2>
                <div className="flex flex-col border-gold border backdrop-blur-sm rounded-md">
                    <div className="flex-col flex gap-3 justify-center items-center w-full px-44 py-4">
                        <img className="w-8"
                             src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAACXBIWXMAAAsTAAALEwEAmpwYAAACCUlEQVR4nO2au04CQRSGp9HGhoJG8SEoraws1EIeQwz0NryDr2GnUV9BJSoUVlZixEvUxgvWn5kwRlgXw8wsy+x6vmQTwg7n/D8zWQ4zRylBEAQhwwCzQB1oAj1G0wHWJpB/3cQehdZ0CtS0Vt9kC0Cb8ekm5vRHw51F/hYw7zOzbYtkkzLctdRwAcy4JKpbJroGVidgeM3EtmHLJVEzEuRAL3EVGEAJOIxoPXEJ9BEJUlKBAixGtL67BBlCBY63XnwDpIwYtgWZ4bCRJW0Lw/XrtQocb730K5yuuRKvoJIma3qF4AEqwDPwBGykNW5qAPcDD427tMYF/ztIwuOmBmI4HpnhDC/pzjiVjsUMh13pMWalY2E4H5UToS/VpPk3hok/QXhVeYX4E4RdlVf4fYLwEuI+9yROEN70zObarJBHgGXgVj+JgcZfZ7fmpLJhlvsjsKKyBv1jy0FugG2gDMyZq2ze0/cGuZyG4IrPjgNwhjuttPUq3x0HYAl4cDCrP7OUtl4VVWEdoB+jAOwAn2MY7ZmxBcdcfnpJwPBArCKwCewDV8Zcz7zeM/eKnjnCMZwGYtgWZIbzv6Tf/1tTSzMS5DBE08bsURJtSzXsmFavZRxVl0SzpneRDPVaYkraGddk8zF/AkLutTx3bi79Rn9buncROI7pzguh1/LDaKs6z6wgCIKgwuALNFnvqwOMU9cAAAAASUVORK5CYII="/>
                        <p className="font-extralight">Network Name</p>
                        <h2> Geers</h2>
                    </div>
                    <div className=" px-32 py-4 border-t border-gold flex-col flex gap-3 justify-center items-center ">

                        <img className="w-8"
                             src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACvUlEQVR4nO3Y2YtPYRzH8ZGxhCylZOxbuSeSkkFI7iz5A0QphSyR7cZ/oCguLFdyZ4kLpSwlS0TZStniymSvQV568vz0M86cOec35ze/Ueddp36dc57n+/nM8z3P9/tMU1NJSUlJSUnJfwwGYyl24Thu4Bna0B6vtngvPDuGnVgSxjZK9GhswTV8UzvtuIJNGNUTwltxHj8Uz3ecxbx6CF+E63qOq5hfVKqc0DjOYlyt4tfgg8bzHivzCB+IwzmDPMIRrMcCTMII9ItX+D0ZC+M7R/E4Z4yDGNCV+GG4nHHC+9iOCTUt8e94E7EDDzLGvIShafv53QyThF1oQa2iU8wsxIUM8e90Wj+wP2VgMNdatPCOxBS8l6Jjf1cTdDQRCtVuNKeMCau3DAdwLuZ3Wxz7I/5+GJ+Fij2ni/masSehSKaLTzDxCjM7eadPrA+n8EV+PsXWY0aKjll4nUt81eB1oQ508mx5/IiLLFyLU2rRulziU0yNx0X14yRGFiI2QfyKHihs7bGeNBctfi5+1ln8qbDChQrPscVWPsrQu2yMu8zkuDsNjHk8GxtwGl+rxn0rLMdrNPEq9vOZDycYEs28wNr6qu7cROjf96J/N+bqm3BvcGxRbuNzvG5hGwZ120AMsjmkRCGTVYGpeJqSpqFATmmqFxgbj5qhX3oeC1zI95c4k3Z09Psv/yTDx/64sJWoCj46VtUs5+PwziEM7zBHSBtV6RnalxaMiW1FuFdha5Hip+Gd/EzvME/I+Qq7E+KE763CzcIMZNxik/jrcBK34QotCTHCSlT4WKiBGCAcTLKyL2H8H1JidPlOT5j4R3yvMZAhnf5pibEab+Xnba7DfgEmEvt5tYmv8KYuBhLSaV/Ke92ibgaqViL1JKU3G8iC0kCDUa5Ag1GuQIPRWwtZDgOrgpBaxId/9fwClTWPmpW17TsAAAAASUVORK5CYII="/>
                        <p className="font-extralight">Network Name</p>
                        <h2> Geers</h2>
                    </div>
                </div>

            </div>
            <br/>
            <br/>
            {/*<div className="">*/}
            {/*    <GuestFooter/>*/}
            {/*</div>*/}
            {/*<span className="absolute bottom-5 right-5 text-white">Powered by <b>Touch Tab</b></span>*/}

        </div>
    );
};

export default Wifi;
