 import {GET, POST} from "../../utils/lib/axios";
import {COMPANY} from "../../constants/endpoints/company";
import {AUTH} from "../../constants/endpoints/auth";
import {ILoginRequest} from "../../models/IAuth";
// import {axiosPublic} from "../../utils/lib/axios";
//
//
// const refreshTokenFn = async () => {
//     const session = JSON.parse(localStorage.getItem("session"));
//
//     try {
//         const response = await axiosPublic.post("/user/refresh", {
//             refreshToken: session?.refreshToken,
//         });
//
//         const { session } = response.data;
//
//         if (!session?.accessToken) {
//             localStorage.removeItem("session");
//             localStorage.removeItem("user");
//         }
//
//         localStorage.setItem("session", JSON.stringify(session));
//
//         return session;
//     } catch (error) {
//         localStorage.removeItem("session");
//         localStorage.removeItem("user");
//     }
// };
//
// const maxAge = 10000;
//
// export const memoizedRefreshToken = mem(refreshTokenFn, {
//     maxAge,
// });

const userLogin = (data: ILoginRequest) => {
    return POST(AUTH.post.login, data)
        .then(response => {
            return response.data;
        });
}
export default {userLogin}
