import i18n from "i18next"

import {initReactI18next} from "react-i18next"



import layoutENG from "../../locales/eng/layout.json"
import layoutFr from "../../locales/fr/layout.json"

import tablesENG from "../../locales/eng/tables.json"
import tablesFr from "../../locales/fr/tables.json"

import commonsENG from "../../locales/eng/commons.json"
import commonsFr from "../../locales/fr/commons.json"

// resources: {
//     en: {
//         namespace1: {
//             key: 'hello from namespace 1'
//         },
//         namespace2: {
//             key: 'hello from namespace 2'
//         }
//     },
//     de: {
//         namespace1: {
//             key: 'hallo von namespace 1'
//         },
//         namespace2: {
//             key: 'hallo von namespace 2'
//         }
//     }
// }

// the translations
const resources = {

    en: {

        layout: layoutENG,
        tables: tablesENG,
        commons: commonsENG,

    }, fr: {
        layout: layoutFr,
        tables: tablesFr,
        commons: commonsFr
    },
}

i18n
    // .use(Backend)
    // .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        lng: localStorage.getItem("I18N_LANGUAGE") || "fr",
        fallbackLng: "en", // use en if detected lng is not available
        debug: true,
        interpolation: {
            escapeValue: false, // react already safes from xss
        },

    })

export default i18n
