import Select, {GroupBase, OptionsOrGroups, Props} from 'react-select';

interface SelectProps extends Props {
    options: OptionsOrGroups<any, GroupBase<any>>;
    className?: string
    labelKey?: string;
    valueKey?: string;
}


const SelectBox = ({
                       options, className, labelKey = 'label',
                       valueKey = 'value', ...selectProps
                   }: SelectProps) => {

    return (
        <Select
            {...selectProps}
            // @ts-ignore
            getOptionLabel={(option) => option[labelKey]}
            // @ts-ignore
            getOptionValue={(option) => option[valueKey]}
            isClearable
            className={className}
            options={options}
        />
    );
};

export default SelectBox;
