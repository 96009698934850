import React, {useEffect, useState} from 'react';
import Modal from "../../../shared/components/Modal";
import HotelAdmins from "./HotelAdmins";
import AddFoodMenu from "./AddFoodMenu";
import {useParams, useSearchParams} from "react-router-dom";
import FoodCard from "./FoodCard";
import AddFoodForm, {IFood} from "../forms/AddFoodForm";
import {GET} from "../../../utils/lib/axios";
import {toast} from "react-toastify";
import {IMeta} from "../../../models/IMeta";
import SelectBox from "../../../shared/components/form/SelectBox";
import {ActionMeta} from "react-select";
import Button from "../../../shared/components/form/Button";
import {capitalizeSentence} from "../../../utils/helpers/str";
import useAuth from "../../../utils/hooks/useAuth";

const FoodMenu = () => {
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [searchParams, setSearchParams] = useSearchParams()
    const {user} = useAuth()
    const [foods, setFoods] = useState<IFood[]>([]);
    const [selectedFood, setSelectedFood] = useState<IFood>();
    const [foodCategories, setFoodCategories] = useState<IMeta[]>([]);
    const [restaurants, setRestaurants] = useState<IMeta[]>([]);
    const [selectedFoodCategory, setSelectedFoodCategory] = useState<IMeta>();
    const [selectedRestaurant, setSelectedRestaurant] = useState<IMeta>();
    const loadRestaurants = () => {
        GET({path: "/hotels/{id}/restaurants"}, {id: user?.hotel?.id}).then(r => {
            setRestaurants(r.data.map(((item: any, i: number) => {
                    console.log(item);
                    if (i == 0) setSelectedRestaurant({
                        id: item.id,
                        name: item.name,
                    })
                    return {
                        id: item.id,
                        name: item.name,
                    }
                }))
            )

        }).catch(e => toast.error(e.response.data.message));
    }
    const loadFoodCategories = () => {
        GET({path: "/meta/foodCategories"}).then(r => {
            console.log(r);
            setFoodCategories(r.data.map(((item: any, i: number) => {
                    return {
                        id: item.id,
                        name: item.name,
                    }
                }))
            )

        }).catch(e => toast.error(e.response.data.message));
    }
    const loadFoods = () => {
        console.log(selectedRestaurant);

        GET({path: "/hotels/{hotelId}/foodMenu"}, {hotelId: 1}, {
            restaurantId: selectedRestaurant?.id,
            foodCategoryId: selectedFoodCategory?.id
        })
            .then(r => {
                // setFoods(r.data)
                setFoods(r.data.map(((item: any, i: number) => {
                        return {
                            name: item.name,
                            id: item.id,
                            price: item.price,
                            description: item.description,
                            category: {id: item.foodCategory.id, name: item.foodCategory.name},
                            type: {id: i, name: capitalizeSentence(item.type)},
                            imageUrl: item.imageUrl,

                        }
                    }))
                )

            })
        // .catch(e => toast.error(e.response.data.message));
    }
    useEffect(() => {
        loadRestaurants()
        loadFoodCategories()
    }, [])
    useEffect(() => {
        loadFoods()
    }, [selectedRestaurant, selectedFoodCategory])
    const handleRestaurantChange = (newValue: any, actionMeta: ActionMeta<any>) => {
        setSelectedRestaurant(newValue);
    };
    return (
        <div className="flex h-full relative">
            <div className="p-2 w-24 flex flex-col  border-gray-400 border-r shrink-0">
                <ul className="flex flex-col gap-2 cursor-pointer">
                    <li onClick={() => setSelectedFoodCategory(undefined)}
                        className={`${selectedFoodCategory == null ? 'bg-blue text-white ' : 'hover:border-blue border-transparent border-2 '}  rounded py-1 px-2`}>All
                    </li>
                    {foodCategories.map((category, i) =>
                        <li onClick={() => setSelectedFoodCategory(category)}
                            className={`${selectedFoodCategory?.id == category.id ? 'bg-blue text-white ' : 'hover:border-blue border-transparent border-2 '}  rounded py-1 px-2`}>{category.name}</li>
                    )}


                </ul>
            </div>
            <div className="flex-auto flex flex-col">
                <div className="p-2 flex border-b border-gray-400 w-full justify-between">
                    <div className="flex gap-3 items-center ">
                        <b>Restaurant:</b>
                        <SelectBox value={selectedRestaurant} onChange={handleRestaurantChange} valueKey="id"
                                   labelKey="name" options={restaurants}/>
                    </div>
                    <Button disabled={selectedRestaurant == null} onClick={() => {
                        setSelectedFood(undefined)
                        setModalOpen(true)
                    }}>Add new</Button>
                </div>
                <div className="grid grid-cols-3 gap-5 p-5 flex-auto ">
                    {foods.map((food, i) =>
                        <div onClick={() => {
                            setSelectedFood(food);
                            setModalOpen(true)
                        }}>
                            <FoodCard food={food}/>
                        </div>
                    )}

                    <Modal
                        title={''}
                        isOpen={modalOpen}
                        onClose={setModalOpen}
                        className="w-8/12"
                    >
                        <AddFoodForm selectedFood={selectedFood} restaurantId={selectedRestaurant?.id!}
                                     foodCategory={foodCategories}/>
                    </Modal>
                </div>

            </div>
        </div>
    );
};

export default FoodMenu;
