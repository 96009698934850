import React, {useEffect, useState} from 'react';
import useAuth from "../../../utils/hooks/useAuth";
import {GET, POST} from "../../../utils/lib/axios";
import {toast} from "react-toastify";
import AccountSetupMultiTemplateWithDropDown from "../../../shared/components/AccountSetupMultiTemplateWithDropDown";

const Restaurants = () => {
    const [data, setData] = useState({restaurants: []})
    const {user} = useAuth()

    const [options, setOptions] = useState<Record<string, any>[]>([{}]);

    const loadOptions = () => {
        GET({path: "/partners"})
            .then(r => {
                setOptions(r.data.map(((item: any, i: number) => {
                        console.log((item));
                        return {label: item.name, value: item.id}
                    }))
                )

            }).catch(e => toast.error(e.response.data.message));
    }
    useEffect(() => {
        loadOptions()
    }, [])

    const handleSave = (data: Record<string, any>) => {

        const body = {
            name: data.name,
            place: data.place,
            openTime: data.openTime,
            closeTime: data.closeTime,
            cuisineType: data.cuisineType,
            description: data.description,
            partner: data.dropdownId ? {id: data.dropdownId, name: data.dropdownLabel} : undefined
        }
        POST({path: "/hotels/{id}/restaurants"}, body, {id: user?.hotel?.id})
            .then(r => {
                toast.success(r.data.message)
                loadData()

            }).catch(e => toast.error(e.response.data.message));
    }
    const handleDelete = (data: Record<string, any>) => {


    }
    const loadData = () => {
        GET({path: "/hotels/{id}/restaurants"}, {id:  user?.hotel?.id}).then(r => {
            console.log(r);
            setData({
                restaurants: r.data.map(((item: any, i: number) => {
                    return {
                        no: i + 1,
                        id: item.id,
                        name: item.name,
                        dropdownId: item.partner?.id,
                        dropdownLabel: item.partner?.name,
                        place: item.place,

                        openTime: item.openTime,
                        closeTime: item.closeTime,
                        cuisineType: item.cuisineType,
                        description: item.description,

                    }
                }))
            })

        }).catch(e => toast.error(e.response.data.message));
    }
    useEffect(() => {
        loadData()
    }, [])
    return (
        <div className="p-5">
            <AccountSetupMultiTemplateWithDropDown
                options={options}
                keyArr={[
                    {key: 'name', header: 'Name'},
                    {key: 'place', header: 'Place'},
                    {key: 'description', header: 'Description'},
                    {key: 'cuisineType', header: 'Cuisine Type'},
                    {key: 'openTime', header: 'Open Time (h)'},
                    {key: 'closeTime', header: 'Close Time (h)'},
                    ]}
                key="s1" data={data?.restaurants} name={"restaurants"}
                handleSave={handleSave}
                // handleDelete={handleDelete}
                setData={setData}
                title=""
                des=""
                addingTitle=""
                dropdownHeader="Partners"/>
        </div>
    );
}
export default Restaurants;
