import {IRoute} from "../models/IRoute";
 import NotFound from "../shared/pages/NotFound";
import {Navigate, Outlet} from "react-router-dom";
import React from "react";
import {ERouteType} from "../constants/enums";
import PageLayout from "../layouts/PageLayout";
 import Default from "../shared/pages/Default";


const CommonRoutes: IRoute[] = [
    {
        path: `/404`,
        component: NotFound,
        routeType: ERouteType.public,
        layout: PageLayout,
        props: {navTitle: 'Food Menu'}

    },
    {
        path: `/`,
        component: () => <Navigate to={"/auth/login"}/>,
        routeType: ERouteType.public,
        layout: PageLayout,
    },
    {
        path: `/dashboard`,
        component: Default,
        routeType: ERouteType.public,
        layout: PageLayout,
    },
    // {
    //     path: `/test`,
    //     component: RequestCoaches,
    //     routeType: ERouteType.public,
    //     layout: PageLayout,
    // },

];

export default CommonRoutes;

export const TestNestedRoutes: IRoute[] = [
    {
        path: "/testroute",

        routeType: ERouteType.public,
        layout: PageLayout,
        routes: [{
            path: "test1",
            component: () => <h1>test1</h1>,
            routeType: ERouteType.public,

        }, {
            path: "test2",
            component: () => <><h1>test2</h1> <Outlet/><h1>test2</h1></>,
            routeType: ERouteType.public,
            routes: [{
                path: "test2-1",
                // index:true,
                component: () => <><h1>test2-1</h1> <Outlet/><h1>test2-1</h1></>,
                routeType: ERouteType.public,
                routes: [{
                    // path: "test2-1-1",
                    index: true,
                    component: () => <h1>test2-1-1</h1>,
                    routeType: ERouteType.public,
                }, {
                    path: "test2-1-2",
                    component: () => <h1>test2-1-2</h1>,
                    routeType: ERouteType.public,

                },]
            },]

        },]
    },

];
