import React, {useEffect, useState} from 'react';
import useAuth from "../../../utils/hooks/useAuth";
import AccountSetupMultiTemplateWithDropDown from "../../../shared/components/AccountSetupMultiTemplateWithDropDown";
import AccountSetupMultiTemplate from "../../../shared/components/AccountSetupMultiTemplate";
import {GET, POST} from "../../../utils/lib/axios";
import {toast} from "react-toastify";

const Partners = () => {
    const [data, setData] = useState<Record<string, any>>({partners: []})
    const {user} = useAuth()
    const handleSave = (data: Record<string, any>) => {
        POST({path: "/partners"}, data)
            .then(r => {
                toast.success(r.data.message)

                loadData()
            }).catch(e => toast.error(e.response.data.message));
    }

    const loadData = () => {
        GET({path: "/partners"})
            .then(r => {
                setData({
                    partners: r.data.map(((item: any, i: number) => {
                        return {
                            no: i + 1,
                            id: item.id,
                            name: item.name,
                            location: item.location,
                            wifiPassword: item.wifiPassword,
                        }
                    }))
                })

            }).catch(e => toast.error(e.response.data.message));
    }
    useEffect(() => {
        loadData()
    }, [])
    return (
        <div className="p-5">
            <AccountSetupMultiTemplate
                handleSave={handleSave}
                // handleDelete={handleDelete}
                keyArr={[{key: 'name', header: 'Name'}, {key: 'location', header: 'Location'}]}
                data={data?.partners} name={"partners"}
                setData={setData}
                title=""
                des=""
                addingTitle=""/>
        </div>
    );
};


export default Partners;
