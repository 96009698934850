import React from 'react';
import homeImg from '../../../assets/img/home.png'
import logoImg from "../../../assets/img/logo.png";
import HomeNavigator from "../components/HomeNavigator";
import {SunIcon} from "@heroicons/react/outline";
 import moment from "moment/moment";
import useAuth from "../../../utils/hooks/useAuth";
import GuestFooter from "../../../layouts/components/GuestFooter";

const Home = () => {
    const {user}= useAuth()
    return (
        <div style={{backgroundImage: `url(${homeImg})`}} className="relative min-h-screen bg-cover bg-bottom text-white">
            <div className="absolute top-8 left-8">
                <h2>{ moment().format('HH:mm')}</h2>
               <span className="flex gap-2 font-extralight items-center"><h2>17&deg;</h2> OUTSIDE <SunIcon className="w-8"/> </span>
            </div>
            <div className="w-2/3 m-auto flex flex-col gap-5 items-center">
                <br/>

                <img className="h-28" src={logoImg}/>
                <h2 className="text-center py-8 px-12">Welcome {user?.name}!</h2>
                <HomeNavigator/>
            </div>
            <br/>
            <br/>
            {/*<div className="">*/}
            {/*    <GuestFooter/>*/}
            {/*</div>*/}

        </div>
    );
};

export default Home;
