import React, {useEffect, useState} from 'react';
import {Form, Formik, FormikHelpers, FormikValues} from "formik";
import * as Yup from "yup";
import InputFormik from "../../../shared/components/formik/InputFormik/InputFormik";
import SelectFormik from "../../../shared/components/formik/SelectFormik";
import InputFormikWithIcon from "../../../shared/components/formik/InputFormik/InputFormikWithIcon";
import {LinkIcon, MailIcon, OfficeBuildingIcon, PhoneIcon, UserIcon} from "@heroicons/react/outline";
import SubmitFormik from "../../../shared/components/formik/SubmitFormik/SubmitFormik";

import MetaApi from "../../../services/api/metaApi";
import {IMeta} from "../../../models/IMeta";
import {toast} from "react-toastify";
import useAuth from "../../../utils/hooks/useAuth";
import {EFoodType} from "../../../constants/enums";
import TextareaFormik from "../../../shared/components/formik/TextareaFormik/TextareaFormik";
import {POST} from "../../../utils/lib/axios";
import SelectCreatableFormik from "../../../shared/components/formik/SelectCreatableFormik";

export interface IFood {
    id?: number
    rate?: number
    name: string
    description?: string
    price: number | null
    category: IMeta | null
    type: IMeta | null
    imageUrl?: string
    count?: number
}

interface Props {
    foodCategory?: IMeta[]
    restaurantId: number
    selectedFood?: IFood
}

const foodInit = {

    name: "",
    description: "",
    price: null,
    category: null,
    type: null,

}
const AddFoodForm = ({foodCategory, restaurantId, selectedFood}: Props) => {

    const {user} = useAuth()

    const [typeOptions, setTypeOptions] = useState<any[]>([
        {id: 'starters', name: 'Starters'},
        {id: 'main_course', name: 'MainCourse'},
        {id: 'desserts', name: 'Desserts'},
        {id: 'drinks', name: 'Drinks'}
    ])
    const [categoryOptions, setCategoryOptions] = useState<IMeta[]>([])

    const [food, setFood] = useState<IFood>(foodInit)
    console.log(food)
    useEffect(() => {
        setFood(selectedFood!)
    }, [selectedFood])
    const _handleSubmit = async (data: FormikValues, actions: FormikHelpers<any>) => {
        console.log(data);
        const body = {
            name: data.name,
            price: data.price,
            description: data.description,
            imageUrl: data.imageUrl,
            foodCategory: data.category,
            type: data.type.id,
        }
        POST({path: "/hotels/{hotelId}/foodMenu"}, body, {hotelId: 1}, {
            restaurantId: restaurantId,
        })
            .then(r => {
                toast.success(r.data.message)
                setFood(foodInit)

            }).catch(e => toast.error(e.response.data.message));
        //   updateCoachProfile(values as ICoachProfile, user?.id!).then((res) => {
        //     actions.setSubmitting(false);
        //     setProfile(res)
        //     toast.success('Updated successful!')
        //
        // }).catch((e) =>
        //     toast.error(e.response.data.message)
        // )
    }
    useEffect(() => {
        // const lng = MetaApi.getLanguages()
        // const spc = MetaApi.getSpecializations()
        //
        // Promise.all([lng, spc]).then(([lng, spc]) => {
        //         setLanguagesOptions(lng)
        //         setSpecializationsOptions(spc)
        //     }
        // ).catch((e) => {
        //     console.log("api error getting meta");
        // }).then(() => getCoachProfile(user?.id!)
        //     .then(res => {
        //         setProfile(res)
        //     }).catch((e) => {
        //         console.log("api error");
        //     }))
        if (foodCategory) setCategoryOptions(foodCategory)
    }, [foodCategory])


    return (
        <div>
            <div className=" mx-auto bg-transparent flex flex-col items-center">
                {/*<h4>{food?.name}</h4>*/}
                {food?.imageUrl && <img className="rounded border border-blue border-4 object-cover shadow"
                                        src={food?.imageUrl}/>}
            </div>
            <Formik
                enableReinitialize={true}
                initialValues={food ? food : {}}

                validationSchema={
                    Yup.object({
                        name: Yup.string()
                            .required('Required'),
                        description: Yup.string()
                            .required('Required'),

                        price: Yup.number()
                            .typeError('Invalid number')
                            .nullable('')
                            .required('Required'),
                        imageUrl: Yup.string().nullable()
                            .required('Required'),
                        category: Yup.object()
                            .shape({
                                id: Yup.string(),
                                name: Yup.string(),
                            })
                            .nullable()
                            .required('This field is required.'),
                        type: Yup.object()
                            .shape({
                                id: Yup.string(),
                                name: Yup.string(),
                            })
                            .nullable()
                            .required('This field is required.'),
                    })}
                onSubmit={_handleSubmit}
            >
                <Form className="flex flex-col gap-6 px-5 py-4">

                    <InputFormik placeholder={"Name"}
                                 label={"Name"} name={"name"}/>

                    <TextareaFormik rows={5} placeholder={"Description"}
                                    label={"Description"} name={"description"}/>


                    <div className="flex flex-col gap-2">
                        <span className="font-medium">Food Type</span>
                        <SelectFormik isMulti={false} name="type" labelKey="name" valueKey="id"
                                      options={typeOptions}/>
                    </div>
                    <div className="flex flex-col gap-2">
                        <span className="font-medium">Food Category</span>
                        <SelectCreatableFormik setOptions={setCategoryOptions} isMulti={false} name="category"
                                               labelKey="name" valueKey="id" options={categoryOptions}/>
                    </div>

                    <InputFormik type="number" placeholder={"Price"}
                                 label={"Price"} name={"price"}/>


                    <InputFormikWithIcon placeholder={"Image url"}
                                         label={"Image Url"} name={"imageUrl"}
                                         HeroIcon={LinkIcon}/>

                    {!selectedFood && <SubmitFormik className="dashboard-button bg-black py-3 justify-center"
                                                    text="Save" onSubmittingText="Saving..."/>}
                </Form>
            </Formik>
        </div>
    );
};

export default AddFoodForm;
