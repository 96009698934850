import React, {useState} from 'react';

import {useLocation, useNavigate} from "react-router-dom";
import {DocumentTextIcon, HomeIcon, KeyIcon, LockClosedIcon, MailIcon} from "@heroicons/react/outline";
import LanguageSelector from "../../../shared/components/LanguageSelector/LanguageSelector";
import i18n from "../../../utils/lib/i18n";
import ENG from "../locale/eng/login.json";
import Fr from "../locale/fr/login.json";
import {useTranslation} from "react-i18next";
import useAuth from "../../../utils/hooks/useAuth";
import introImg from "../../../assets/img/intro.png";

interface LocationProps {
    state: {
        from: Location;
    };
};


const Login = () => {
    i18n.addResourceBundle('en', 'login', ENG);
    i18n.addResourceBundle('fr', 'login', Fr);
    const {t} = useTranslation('login')

    const navigate = useNavigate();
    const {login,} = useAuth();
    const {state} = useLocation() as LocationProps;
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    console.log(state);


    return (

            <div style={{backgroundImage: `url(${introImg})`}} className="min-h-screen bg-cover bg-center flex items-center justify-center h-full  ">
                <div className="backdrop-blur-sm flex flex-col p-12 border border-white rounded">

                    <div className="flex justify-center text-white">
                        {/*<img className={"w-1/2 m-8"} src={logoImg}/>*/}
                        <h1>Touch Tab</h1>
                    </div>
                    <div className="flex flex-col justify-center items-center">
                        <div className="text-white flex flex-col gap-3   p-5">
                            <h4>Login</h4>
                            <div className="mt-4 relative text-gray-600 rounded">
                                <button className="absolute left-2 top-0 bottom-0 mr-4">
                                    <MailIcon className="text-gray-600 h-5 w-5"/>

                                </button>
                                <input
                                    onChange={e => setEmail(e.target.value)}

                                    className="w-full border-2 border-gray-light bg-white h-10 px-5 pl-8 rounded text-sm focus:outline-none"
                                    name="email"/>
                            </div>
                            <div className=" relative text-gray-600 rounded w-full">
                                <button className="absolute left-2 top-0 bottom-0 mr-4">
                                    <KeyIcon className="text-gray-600 h-5 w-5"/>

                                </button>
                                <input
                                    onChange={e => setPassword(e.target.value)}
                                    className="w-full border-2 border-gray-light bg-white h-10 px-5 pl-8 rounded text-sm focus:outline-none"
                                    name="password"
                                    type="password"/>

                            </div>
                            <button disabled={email == '' || password == ''}
                                    className="dashboard-button bg-blue-900 m-auto"
                                    onClick={() => login({email: email, password: password})}>
                                Login
                            </button>
                        </div>
                    </div>

                </div>

                {/*<div className="absolute bottom-0">*/}
                {/*    <LanguageSelector/>*/}
                {/*</div>*/}
            </div>
     );
};


export default Login;
