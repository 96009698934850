import React, {useEffect, useState} from 'react';
import {GET} from "../../../utils/lib/axios";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";
import RestaurantCard from "../components/RestaurantCard";
import homeImg from "../../../assets/img/home.png";
import BabySitterCard from "../components/BabySitterCard";
import useAuth from "../../../utils/hooks/useAuth";

const BabySitter = () => {
    const [babySitters, setBabySitters] = useState<Record<string, any>[]>([]);
    const {user} = useAuth()

    const loadBabySitters = () => {
        GET({path: "/hotels/{id}/babySitters"}, {id: user?.hotel?.id}).then(r => {
            setBabySitters(r.data)
        }).catch(e => toast.error(e.response.data.message));
    }
    useEffect(() => {
        loadBabySitters()
    }, [])
    return (

        <div className="p-5 grid grid-cols-3 grid-rows-2 h-full gap-5 ">
            {babySitters.map((babySitter, i) =>
                <Link to={`./${babySitter.id}`}
                      className={``}>
                    <BabySitterCard  babySitter={babySitter}
                    />

                </Link>
            )}


        </div>

    );
};
export default BabySitter;

