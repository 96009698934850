import React, {useState} from 'react';
import {Link, useParams} from "react-router-dom";
import roomImg from "../../../assets/img/bgwifi.png";
import MyDatePicker from "../../../shared/components/MyDatePicker";
import {CalendarIcon} from "@heroicons/react/outline";
import {ClockIcon} from "@heroicons/react/solid";
import Button from "../../../shared/components/form/Button";

const DateLabel = ({value, onClick, Icon}: any) => (
    <div className="flex gap-5 items-center font-bold justify-between w-full">
        {value}
        <button className="" onClick={onClick}>{Icon}</button>

    </div>
);
const MeetingRoomBooking = () => {
    const [startDate, setStartDate] = useState(new Date());
    const [startTime, setStartTime] = useState(new Date());
    const [endTime, setEndTime] = useState(new Date());
    const {id} = useParams()

    return (
        <div style={{backgroundImage: `url(${roomImg})`}}
             className="flex flex-col relative min-h-screen bg-cover bg-top text-white">
            <br/>
            <br/>
            <br/>
            <div className="flex gap-5 flex-col p-5 mr-auto">
                <p>Need help finding room for meeting? We can help!
                </p>
                <h2>
                    Book a Seat in Meeting Room</h2>
                <div className="border border-gold rounded-2xl px-5 py-2 backdrop-blur-2xl z-10">
                    <p>Date</p>
                    <MyDatePicker selected={startDate}
                                  onChange={(date: any) => setStartDate(date)}
                                  customInput={<DateLabel Icon={<CalendarIcon className="w-8 h-8"/>}/>}
                    />

                </div>
                <div className="flex gap-5 w-full justify-between">
                    <div className="border border-gold rounded-2xl px-5 py-2 backdrop-blur-2xl w-full">
                        <p>Starting Time</p>
                        <MyDatePicker selected={startTime}
                                      showTimeSelect
                                      showTimeSelectOnly
                                      timeIntervals={60}
                                      dateFormat="h:mm aa" onChange={(date: any) => setStartTime(date)}
                                      customInput={<DateLabel Icon={<ClockIcon className="w-8 h-8"/>}/>}
                        />

                    </div>
                    <div className="border border-gold rounded-2xl px-5 py-2 backdrop-blur-2xl w-full">
                        <p>Ending Time</p>
                        <MyDatePicker selected={endTime}
                                      showTimeSelect
                                      showTimeSelectOnly
                                      timeIntervals={60}
                                      dateFormat="h:mm aa" onChange={(date: any) => setEndTime(date)}
                                      customInput={<DateLabel Icon={<ClockIcon className="w-8 h-8"/>}/>}
                        />

                    </div>
                </div>
                <br/>
                <Link to="/thank-you">
                    <Button className="m-auto border border-white">Confirm</Button>
                </Link>
            </div>
        </div>
    );
};

export default MeetingRoomBooking;
