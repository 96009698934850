import React, {useState} from 'react';
import Navbar from "./components/Navbar";
import {Outlet} from "react-router-dom";
import GuestFooter from "./components/GuestFooter";
import GuestNavbar from "./components/GuestNavbar";

const GuestLayout = () => {
    const [navTitle, setNavTitle] = useState();

    return (
        <div className="bg-warm flex-auto h-screen w-screen overflow-auto flex flex-col">
            <GuestNavbar navTitle={navTitle}/>
            <main className="bg-warm m-0 flex-auto">
                <Outlet context={{navTitle, setNavTitle}}/>
            </main>
            <GuestFooter/>
        </div>
    );
};

export default GuestLayout;
