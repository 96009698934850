import React, {useEffect, useState} from 'react';
import AccountSetupMultiTemplate from "../../../shared/components/AccountSetupMultiTemplate";
import useAuth from "../../../utils/hooks/useAuth";
import Modal from "../../../shared/components/Modal";
import HotelAdmins from "./HotelAdmins";
import {GET, POST} from "../../../utils/lib/axios";
import {toast} from "react-toastify";

const Hotels = () => {
        const [data, setData] = useState<Record<string, any>>({hotels: []})
        const {user} = useAuth()
        const [modalOpen, setModalOpen] = useState<boolean>(false);
        const [selectedHotel, setSelectedHotel] = useState<Record<string, any>>();

        const onRowClick = (data: Record<string, any>) => {
            setSelectedHotel({id: data.id, name: data.name})
            setModalOpen(true)
        }
        const handleSave = (data: Record<string, any>) => {
            POST({path: "/hotels"}, data)
                .then(r => {
                    toast.success(r.data.message)
                    loadData()
                }).catch(e => toast.error(e.response.data.message));
        }

        const loadData = () => {
            GET({path: "/hotels"})
                .then(r => {
                    setData({
                        hotels: r.data.map(((item: any, i: number) => {
                            return {
                                no: i + 1,
                                id: item.id,
                                name: item.name,
                                location: item.location,
                                wifiPassword: item.wifiPassword,
                            }
                        }))
                    })

                }).catch(e => toast.error(e.response.data.message));
        }
        useEffect(() => {
            loadData()
        }, [])
        return (
            <div className="p-5">
                <AccountSetupMultiTemplate
                    onRowClick={onRowClick}
                    handleSave={handleSave}
                    // handleDelete={handleDelete}
                    keyArr={[{key: 'name', header: 'Name'},
                        {key: 'location', header: 'Location'},
                        {key: 'wifiPassword', header: 'Wifi Password'},
                    ]}
                    data={data?.hotels} name={"hotels"}
                    setData={setData}
                    title=""
                    des=""
                    addingTitle=""/>
                <Modal
                    title={''}
                    isOpen={modalOpen}
                    onClose={setModalOpen}
                    className="w-8/12"
                >
                    <HotelAdmins hotelId={selectedHotel?.id} hotelName={selectedHotel?.name}/>

                </Modal>
            </div>
        );
    }
;

export default Hotels;
