import React from 'react';
import FoodCard from "../../dashboard/pages/FoodCard";
import {IFood} from "../../dashboard/forms/AddFoodForm";
import Button from "../../../shared/components/form/Button";
import {number} from "yup";
import {Link} from "react-router-dom";

export interface ICart {

    items: {
        [id: number]: {
            food: IFood
            quantity: number
        }
    }
    // setFoods: React.Dispatch<React.SetStateAction<IFood[]>>
}

const Cart = ({items={}}: Record<any, any>) => {
    const totalQuantity = Object.values(items).reduce((acc: number, item: any) => {
        return acc + parseInt(item.quantity, 10);
    }, 0);
    const totalPrice = Object.values(items).reduce((acc: number, item: any) => {
        return acc + parseFloat(item.food.price);
    }, 0);
    return (
        <div className="flex flex-col gap-5 pt-3">
            <div className="bg-black p-5 text-white flex flex-col gap-3 rounded">
                <span> <b className="font-bold">{totalQuantity}</b> : ITEM(S) HAS BEEN ADDED TO YOUR CART</span>
                <br/>
                <span> Total : <b className="font-bold">${totalPrice}</b></span>
                <Link to="/thank-you">
                <Button className="ml-auto border border-white">
                    Place a Table
                </Button>
                </Link>
            </div>
            <div className="grid grid-cols-3 gap-5">
                {Object.keys(items).map((k: string, i) =>
                    <div className="relative" onClick={() => {

                    }}>
                        <div
                            className="absolute font-bold right-2 top-2 p-1 rounded-full bg-blue text-white w-12 h-12 flex justify-center items-center">{items[k].quantity}</div>
                        <FoodCard food={items[k].food}/>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Cart;
