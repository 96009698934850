import DashboardRoutes from "../modules/dashboard/routes/routes";
import {IRoute} from "../models/IRoute";

import CommonRoutes, {TestNestedRoutes} from "./common";
import AuthRoutes from "../modules/auth/routes/routes";
import GuestRoutes from "../modules/guest/routes";


const routes: IRoute[] = [
    ...AuthRoutes,
    ...DashboardRoutes,
    ...GuestRoutes,
    ...CommonRoutes,

    ...TestNestedRoutes,

]

export default routes;
