import React, {useEffect, useRef, useState} from 'react';

import Table from "./Table";
  import {ActionMeta, GroupBase, OptionsOrGroups} from "react-select";
import axios from "axios";
import {toast} from "react-toastify";
import useAuth from "../../utils/hooks/useAuth";
import SelectBox from "./form/SelectBox";
import Button from "./form/Button";
import Input from "./form/Input";

interface Props {
    title: string;
    name: string;
    // name: keyof IAccountSetup;
    des: string;
    addingTitle: string;
    keyArr: Array<Record<string, any>>
    data: Array<Record<string, any>>;
    handleSave?: (data: Record<string, any>) => void
    handleDelete?: (data: Record<string, any>) => void
    setData: React.Dispatch<React.SetStateAction<any>>;
    options: Record<string, any>[]
    dropdownHeader: string
    defaultOption?:Record<string, any>
}

const AccountSetupMultiTemplateWithDropDown = ({data, setData, name, keyArr, options,dropdownHeader,defaultOption, ...props}: Props) => {
    const resetValue = () => keyArr.reduce((dict, {key}) => ({...dict, [key]: ''}), {})
    const [value, setValue] = useState<Record<string, any>>(resetValue())
    const [selectedOption, setSelectedOption] = useState<Record<string, any>>();
    const {user} = useAuth()
    console.log(defaultOption);
    useEffect(()=>{
        setSelectedOption(defaultOption)
    },[defaultOption])
    const addValue = () => {
        const obj = keyArr.reduce((dict, {key}) => ({...dict, [key]: value ? value[key] : ''}), {});
        if (props.handleSave) {
            props.handleSave({
                dropdownLabel: selectedOption?.label,
                dropdownId: selectedOption?.value, ...value
            })
        }
        console.log(data);
        setData((prevState: any) => ({
            ...prevState,
            [name]: [...(prevState[name]), {
                no: prevState[name].length + 1,
                dropdownLabel: selectedOption?.label,
                dropdownId: selectedOption?.value,
                ...obj,
            }]
        }))
        setValue(resetValue())
    }

    const columns = React.useMemo(
        () => [
            {
                Header: "Id",
                accessor: "id",

            }, {
                Header: "No",
                accessor: "no",
                // Cell: (cell: any) => <NameCell icon={cell.row.original.image_url} text={cell.value}/>

            },
            {
                Header: "Dropdown Id",
                accessor: "dropdownId",
                // Cell: (cell: any) => <NameCell icon={cell.row.original.image_url} text={cell.value}/>

            },
            {
                Header: dropdownHeader,
                accessor: "dropdownLabel",
                // Cell: (cell: any) => <NameCell icon={cell.row.original.image_url} text={cell.value}/>

            }

            , ...keyArr.map(({key, header}, index) => ({
                Header: header,
                accessor: key,
                Cell: (cell: any) => (index === keyArr.length - 1) && props.handleDelete ?
                    <div className="flex gap-3 justify-between"> {cell.value}
                        <button className="text-myOrange hover:text-red-700" onClick={() => {
                            if (props.handleDelete && cell.row.original.email) {
                                props.handleDelete(cell.row.original)
                            }
                            // ES6 Syntax use the rvalue if your data is an array.
                            const dataCopy = [...data];
                            // It should not matter what you name tableProps. It made the most sense to me.
                            dataCopy.splice(cell.row.index, 1);
                            setData((prevState: Record<string, any>) => ({
                                ...prevState,
                                [name]: dataCopy
                            }))
                        }}>&#x2715;</button>
                    </div> : cell.value

            }))

        ],
        [data]
    );
    const handleChange = (newValue: any, actionMeta: ActionMeta<any>) => {
        setSelectedOption(newValue);
    };
    return (
        <div>
            <h4 className="pb-4">{props.title}</h4>
            <p>{props.des}</p>

            <div className="mt-8">
                <p>{props.addingTitle}</p>
                <div className="mb-5 flex gap-0.5 items-center">
                    <div className="grid grid-cols-2 flex-auto gap-4">
                        <div className="w-full">
                            <p className="mb-2">{dropdownHeader}</p>
                            <SelectBox
                                value={selectedOption}
                                       onChange={handleChange}
                                       options={options}/>
                        </div>

                        {keyArr.map((({key}, i) =>
                            <Input key={i} label={key.charAt(0).toUpperCase() + key.slice(1)} value={value?.[key]}
                                   onChange={(e) => setValue(prevState => ({
                                       ...prevState,
                                       [key]: e.target.value
                                   }))}
                                   className="mr-4" name={key}/>))}
                    </div>
                    {/*<Input label="Email" value={value?.email} onChange={(e) => setValue(prevState => ({*/}
                    {/*    ...prevState,*/}
                    {/*    email: e.target.value*/}
                    {/*}))}*/}
                    {/*       className="mr-4" name={"email"}/>*/}
                    {/*<Input label="Team" value={value?.team} onChange={(e) => setValue(prevState => ({*/}
                    {/*    ...prevState,*/}
                    {/*    team: e.target.value*/}
                    {/*}))}*/}
                    {/*       className="mr-4" name={"team"}/>*/}
                    <Button onClick={() => addValue()}>Add</Button>
                </div>
                <div className="w-full">
                    {data?.length! > 0 &&
                        <Table initialState={{hiddenColumns: ['id', 'dropdownId']}} columns={columns} data={data!}/>}

                </div>

            </div>
        </div>
    );
};

export default AccountSetupMultiTemplateWithDropDown;
