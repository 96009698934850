import Select, {GroupBase, OptionsOrGroups, StylesConfig, Props as SelectProps} from "react-select";
import React, {ReactElement} from "react";
import StateManagedSelect from "react-select/dist/declarations/src/stateManager";
import {FieldHookConfig, useField, useFormikContext} from "formik";
//
// export interface ColourOption {
//     readonly value: string;
//     readonly label: string;
//     readonly color: string;
//     readonly isFixed?: boolean;
//     readonly isDisabled?: boolean;
// }
//
// export const colourOptions: readonly ColourOption[] = [
//     {value: 'ocean', label: 'Ocean', color: '#00B8D9', isFixed: true},
//     {value: 'blue', label: 'Blue', color: '#0052CC', isDisabled: true},
//     {value: 'purple', label: 'Purple', color: '#5243AA'},
//     {value: 'red', label: 'Red', color: '#FF5630', isFixed: true},
//     {value: 'orange', label: 'Orange', color: '#FF8B00'},
//     {value: 'yellow', label: 'Yellow', color: '#FFC400'},
//     {value: 'green', label: 'Green', color: '#36B37E'},
//     {value: 'forest', label: 'Forest', color: '#00875A'},
//     {value: 'slate', label: 'Slate', color: '#253858'},
//     {value: 'silver', label: 'Silver', color: '#666666'},
// ];
// const colourStyles: StylesConfig<ColourOption, true> = {
//     control: (styles) => ({...styles, backgroundColor: 'white'}),
//     option: (styles, {data, isDisabled, isFocused, isSelected}) => {
//
//         return {
//             ...styles,
//             backgroundColor: isDisabled
//                 ? undefined
//                 : isSelected
//                     ? data.color
//                     : isFocused
//                         ? data.color
//                         : undefined,
//             color: isDisabled
//                 ? '#ccc'
//                 : isSelected
//                     ? data.color
//                     : data.color,//dropdown background
//             cursor: isDisabled ? 'not-allowed' : 'default',
//
//             ':active': {
//                 ...styles[':active'],
//                 backgroundColor: !isDisabled
//                     ? isSelected
//                         ? data.color
//                         : data.color
//                     : undefined,
//             },
//         };
//     },
//     multiValue: (styles, {data}) => {
//
//         return {
//             ...styles,
//             backgroundColor: '#ccc', //chip background
//         };
//     },
//     multiValueLabel: (styles, {data}) => ({
//         ...styles,
//         color: data.color,
//     }),
//     multiValueRemove: (styles, {data}) => ({
//         ...styles,
//         color: data.color,
//         ':hover': {
//             backgroundColor: data.color,
//             color: 'white',
//         },
//     }),
// };

const colourStyles: StylesConfig<any, true> = {
    control: (styles) => ({...styles, backgroundColor: 'white'}),
    option: (styles, {data, isDisabled, isFocused, isSelected}) => {
        return {
            ...styles,
            backgroundColor: isDisabled
                ? undefined
                : isSelected
                    ? 'rgba(231,93,93,0.84)'
                    : isFocused
                        ? 'rgba(231,93,93,0.35)'
                        : undefined,
            color: isDisabled
                ? '#ccc'
                : isSelected
                    ? 'white'
                    : 'black'
            ,
            cursor: isDisabled ? 'not-allowed' : 'default',

            ':active': {
                ...styles[':active'],
                backgroundColor: !isDisabled
                    ? isSelected
                        ? 'rgba(203,61,61,0.84)'
                        : 'rgba(196,67,67,0.84)'
                    : undefined,
            },
        };
    },

    multiValue: (styles, {data}) => {
        return {
            ...styles,
            backgroundColor: 'rgba(255, 86, 48, 0.1)',
        };
    },
    multiValueLabel: (styles, {data}) => ({
        ...styles,
        color: 'rgb(0,0,0)',
    }),
    multiValueRemove: (styles, {data}) => ({
        ...styles,
        color: 'rgb(255, 86, 48)',
        ':hover': {
            backgroundColor: 'rgba(231,93,93,0.84)',
            color: 'white',
        },
    }),
};

interface Props {
    options: OptionsOrGroups<any, GroupBase<any>> | undefined;
    isMulti?: boolean;
    isDisabled?: boolean;
    labelKey?: string;
    valueKey?: string;
}


// we can use any object with even multiple keys, it will return with value


export default ({
                    options,
                    isDisabled = false,
                    isMulti = true,
                    labelKey = 'label',
                    valueKey = 'value',
                    ...props
                }: Props & FieldHookConfig<string>) => {
    const [field, meta, helpers] = useField(props.name);

    // console.log(field)
    return <div className="">
        <Select
            closeMenuOnSelect={true}
            {...field}

            onChange={value => helpers.setValue(value)}
            isLoading={options?.length == 0}
            // defaultValue={[colourOptions[0], colourOptions[1]]}
            getOptionLabel={(option) => option[labelKey]}
            getOptionValue={(option) => option[valueKey]}
            isDisabled={isDisabled}
            // @ts-ignore
            isMulti={isMulti}
            options={options}
            styles={colourStyles}
            onBlur={()=>helpers.setTouched( true)}
        />
        {meta.touched && meta.error ? (
            <span className="text-red font-extralight">{meta.error}</span>
        ) : null}</div>

};
