import React, {InputHTMLAttributes, TextareaHTMLAttributes} from 'react';

const Textarea = ({className, label, ...props}: TextareaHTMLAttributes<HTMLTextAreaElement> & { label?: string }) => {
    return (
        <label className="flex flex-col  gap-2 w-full">{label}
            <textarea className={`border-black border leading-5 p-2 ${className}`} {...props}/>
        </label>

    );
};

export default Textarea;
