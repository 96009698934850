import React, {useState} from 'react';
import {Outlet} from "react-router-dom";

const PageLayout  = () => {

    return (
  <div className="h-screen">
      <Outlet context=""/>
  </div>
    );
};

export default PageLayout;
