import React, {FC, InputHTMLAttributes} from 'react';


import {Formik, Form, useField, FieldHookConfig, FieldInputProps, useFormikContext} from 'formik';

import style from "./InputFormik.module.css";
import {setClasses} from "../../../../utils/helpers/css";

interface Props {
    label: string;


}

const InputFormik: FC<Props & (FieldHookConfig<string>)> = ({label, ...props}) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input>. We can use field meta to show an error
    // message if the field is invalid and it has been touched (i.e. visited)

    const [field, meta] = useField(props);

    return (

        <div className={setClasses(style.wrapper, (meta.touched && meta.error) && style.error)}>
            <label className={style.label} htmlFor={props.id || props.name}>{label}</label>

            <input className={style.input + " pl-2"} {...field} value={field.value ?? ""}
                   {...props as InputHTMLAttributes<HTMLInputElement>} />

            {meta.touched && meta.error ? (
                <span className={style.errorLabel}>{meta.error}</span>
            ) : null}

        </div>

    );

};

export default InputFormik;
